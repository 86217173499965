@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 300;
  src: local(Roboto Light), local(Roboto-Light), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 400;
  src: local(Roboto), local(Roboto-Regular), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Bold), local(Roboto-Bold), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 400;
  src: local(Roboto Slab Regular), local(RobotoSlab-Regular), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Slab Bold), local(RobotoSlab-Bold), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-700.woff") format("woff");
  font-display: swap;
}

.sport-color, a.sport-color {
  color: #d21e1e;
}

.sport-color-bg {
  background-color: #d21e1e;
}

.sport-color-border {
  border-color: #d21e1e;
}

.sport-color-hover:hover, a.sport-color-hover:hover {
  color: #d21e1e;
}

.sport-color-hover-bg-darken:hover, a.sport-color-hover-bg-darken:hover {
  background-color: #a51818;
}

.sport-color-hover-bg-lighten:hover, a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.sport-color-svg path {
  fill: #d21e1e;
}

.football .sport-color, .football a.sport-color {
  color: #00a442;
}

.football .sport-color-bg {
  background-color: #00a442;
}

.football .sport-color-bg-darken {
  background-color: #00712d;
}

.football .sport-color-border {
  border-color: #00a442;
}

.football .sport-color-hover:hover, .football a.sport-color-hover:hover {
  color: #00a442;
}

.football .sport-color-hover-bg-darken:hover, .football a.sport-color-hover-bg-darken:hover {
  background-color: #00712d;
}

.football .sport-color-hover-bg-lighten:hover, .football a.sport-color-hover-bg-lighten:hover {
  background-color: #00d757;
}

.football .sport-color-svg path {
  fill: #00a442;
}

.football .active .sport-color-active {
  color: #00a442;
}

.football-euro .sport-color, .football-euro a.sport-color {
  color: #02849a;
}

.football-euro .sport-color-svg path {
  fill: #02849a;
}

.hockey .sport-color, .hockey a.sport-color {
  color: #325edc;
}

.hockey .sport-color-bg {
  background-color: #325edc;
}

.hockey .sport-color-bg-darken {
  background-color: #2048bb;
}

.hockey .sport-color-border {
  border-color: #325edc;
}

.hockey .sport-color-hover:hover, .hockey a.sport-color-hover:hover {
  color: #325edc;
}

.hockey .sport-color-hover-bg-darken:hover, .hockey a.sport-color-hover-bg-darken:hover {
  background-color: #2048bb;
}

.hockey .sport-color-hover-bg-lighten:hover, .hockey a.sport-color-hover-bg-lighten:hover {
  background-color: #5e80e3;
}

.hockey .sport-color-svg path {
  fill: #325edc;
}

.hockey .active .sport-color-active {
  color: #325edc;
}

.tennis .sport-color, .tennis a.sport-color {
  color: #ff6000;
}

.tennis .sport-color-bg {
  background-color: #ff6000;
}

.tennis .sport-color-bg-darken {
  background-color: #cc4d00;
}

.tennis .sport-color-border {
  border-color: #ff6000;
}

.tennis .sport-color-hover:hover, .tennis a.sport-color-hover:hover {
  color: #ff6000;
}

.tennis .sport-color-hover-bg-darken:hover, .tennis a.sport-color-hover-bg-darken:hover {
  background-color: #cc4d00;
}

.tennis .sport-color-hover-bg-lighten:hover, .tennis a.sport-color-hover-bg-lighten:hover {
  background-color: #ff8033;
}

.tennis .sport-color-svg path {
  fill: #ff6000;
}

.tennis .active .sport-color-active {
  color: #ff6000;
}

.other .sport-color, .other a.sport-color {
  color: #d21e1e;
}

.other .sport-color-bg {
  background-color: #d21e1e;
}

.other .sport-color-border {
  border-color: #d21e1e;
}

.other .sport-color-hover:hover, .other a.sport-color-hover:hover {
  color: #d21e1e;
}

.other .sport-color-hover-bg-darken:hover, .other a.sport-color-hover-bg-darken:hover {
  background-color: #a51818;
}

.other .sport-color-hover-bg-lighten:hover, .other a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.other .sport-color-svg path {
  fill: #d21e1e;
}

.other .active .sport-color-active {
  color: #d21e1e;
}

.dark .sport-color, .dark a.sport-color {
  color: #1e1e1e;
}

.dark .sport-color-bg {
  background-color: #1e1e1e;
}

.dark .sport-color-bg-darken {
  background-color: #050505;
}

.dark .sport-color-border {
  border-color: #1e1e1e;
}

.dark .sport-color-hover:hover, .dark a.sport-color-hover:hover {
  color: #1e1e1e;
}

.dark .sport-color-hover-bg-darken:hover, .dark a.sport-color-hover-bg-darken:hover {
  background-color: #050505;
}

.dark .sport-color-hover-bg-lighten:hover, .dark a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.dark .sport-color-svg path {
  fill: #1e1e1e;
}

.dark .active .sport-color-active {
  color: #1e1e1e;
}

.oh .sport-color {
  color: #77c10e;
}

.oh .sport-color-border {
  border-color: #77c10e;
}

.oh .sport-color-svg path {
  fill: #77c10e;
}

.oh.participants-detail .table-default-small a:hover {
  color: #1e1e1e;
}

.oh.participants-detail .table-default-small a.online-match-running, .oh.participants-detail .table-default-small a.online-match-running:hover {
  color: #d21e1e;
}

.zoh-pchjongcchang .sport-color {
  color: #006cb5 !important;
}

.zoh-pchjongcchang .sport-color-border {
  border-color: #006cb5 !important;
}

.zoh-pchjongcchang .icon-medail-green.medail-blue {
  background: url("/images/isportcz/dist/non-sprite/zoh-medail-blue.png");
}

.zoh-pchjongcchang a:hover {
  color: #006cb5;
}

.zoh-pchjongcchang .sport-color-svg path {
  fill: #006cb5;
}

.zoh-pchjongcchang .smart-underline:hover {
  color: #006cb5;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #006cb5;
}

.light .sport-color, .light a.sport-color {
  color: #fff;
}

.light .sport-color-bg {
  background-color: #fff;
}

.light .sport-color-svg path {
  fill: #fff;
}

.supperlife-body .other .sport-color, .supperlife-body .other a.sport-color {
  color: #02a555;
}

.supperlife-body .other .sport-color-bg {
  background-color: #02a555;
}

.supperlife-body .other .sport-color-border {
  border-color: #02a555;
}

.supperlife-body .other .sport-color-hover:hover, .supperlife-body .other a.sport-color-hover:hover {
  color: #02a555;
}

.supperlife-body .other .sport-color-hover-bg-darken:hover, .supperlife-body .other a.sport-color-hover-bg-darken:hover {
  background-color: #01733b;
}

.supperlife-body .other .sport-color-hover-bg-lighten:hover, .supperlife-body .other a.sport-color-hover-bg-lighten:hover {
  background-color: #03d76f;
}

.supperlife-body .other .sport-color-svg path {
  fill: #02a555;
}

.supperlife-body .other .active .sport-color-active {
  color: #02a555;
}

.loh-tokio .sport-color {
  color: #94754d !important;
}

.loh-tokio .sport-color-border {
  border-color: #94754d !important;
}

.loh-tokio .sport-color-svg path {
  fill: #94754d;
}

.loh-paris .sport-color {
  color: #ae9e56 !important;
}

.loh-paris .sport-color-border {
  border-color: #ae9e56 !important;
}

.loh-paris .sport-color-svg path {
  fill: #ae9e56;
}

.zoh-peking .sport-color {
  color: #017eff !important;
}

.zoh-peking .sport-color-border {
  border-color: #017eff !important;
}

.zoh-peking .sport-color-svg path {
  fill: #017eff;
}

ul.list-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-links li {
  color: #1e1e1e;
  text-transform: uppercase;
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  list-style: none;
  display: inline-block;
}

ul.list-links li:last-child {
  margin: 0;
}

ul.list-links li a {
  color: #1e1e1e;
  text-decoration: none;
}

ul.list-links li a:hover {
  color: #d21e1e;
}

ul.list-links.light li, ul.list-links.light li a {
  color: #e6e6e6;
}

ul.list-links.simple li {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
}

ul.list-links.list-links-vertical li {
  text-transform: none;
  border-bottom: 1px solid #dcdcdc;
  margin: 0;
  display: block;
}

ul.list-links.list-links-vertical li:last-child {
  border-bottom: none;
}

ul.links-separated li {
  margin-right: 30px;
  font-size: 13px;
  position: relative;
}

ul.links-separated li a:after {
  content: "";
  background-color: #1e1e1e;
  width: 1px;
  height: 11px;
  margin: 4px 15px 0;
  position: absolute;
}

ul.links-separated li:last-of-type a {
  margin-right: 0;
}

ul.links-separated li:last-of-type a:after {
  content: none;
}

.football .links-separated a:after {
  background-color: #00a442;
}

.hockey .links-separated a:after {
  background-color: #325edc;
}

.tennis .links-separated a:after {
  background-color: #ff6000;
}

.menu-tabs {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.menu-tabs li {
  float: left;
  color: #1e1e1e;
  text-transform: uppercase;
  width: 33%;
  margin: 0;
  padding: 0 0 2px 4px;
  font-size: 13px;
  font-weight: 700;
  list-style: none;
}

.menu-tabs__item, .menu-tabs__item-inline {
  color: #aaa;
  text-transform: none;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 3px;
  height: 42px;
  margin: 0 2px 6px;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  display: block;
}

.menu-tabs__item.active, .menu-tabs__item:hover, .menu-tabs__item-inline.active, .menu-tabs__item-inline:hover {
  color: #1e1e1e;
  border-color: #d21e1e;
  text-decoration: none;
}

.menu-tabs__item-inline {
  height: 40px;
  margin: 0 6px 5px 0;
  padding: 0 10px;
}

.menu-tabs.football .menu-tabs__item.active, .menu-tabs.football .menu-tabs__item:hover, .menu-tabs.football .menu-tabs__item-inline.active, .menu-tabs.football .menu-tabs__item-inline:hover {
  border-color: #00a442;
}

.menu-tabs.hockey .menu-tabs__item.active, .menu-tabs.hockey .menu-tabs__item:hover, .menu-tabs.hockey .menu-tabs__item-inline.active, .menu-tabs.hockey .menu-tabs__item-inline:hover {
  border-color: #325edc;
}

.menu-tabs.tennis .menu-tabs__item.active, .menu-tabs.tennis .menu-tabs__item:hover, .menu-tabs.tennis .menu-tabs__item-inline.active, .menu-tabs.tennis .menu-tabs__item-inline:hover {
  border-color: #ff6000;
}

.menu-tabs.others .menu-tabs__item.active, .menu-tabs.others .menu-tabs__item:hover, .menu-tabs.others .menu-tabs__item-inline.active, .menu-tabs.others .menu-tabs__item-inline:hover {
  border-color: #d21e1e;
}

.menu-tabs--inline li {
  width: auto;
  min-width: 110px;
  padding: 0;
}

.menu-tabs--inline li:last-child a {
  margin-right: 0;
}

.menu-tab__content {
  display: none;
}

.menu-tab__content.current {
  display: block;
}

hr {
  background-color: #dcdcdc;
  border: 0;
}

.dropdown ul {
  display: inline-table;
}

.dropdown ul ul {
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  display: none;
}

.dropdown ul li {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu) ul {
  transition: all;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s .2s;
  display: block;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul li a, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul li a, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul li a {
  cursor: pointer;
}

.dropdown ul li.dropdown-show > ul {
  opacity: 1;
  z-index: 2;
  visibility: visible;
  pointer-events: auto;
  transition: opacity .5s .2s;
  display: block;
}

.dropdown ul li.dropdown-show > ul li ul {
  top: 0;
  right: -100%;
}

.dropdown-menu {
  float: left;
  font-weight: 700;
}

.dropdown-menu ul {
  padding: 0;
  list-style: none;
  position: relative;
}

.dropdown-menu li {
  float: left;
  display: block;
}

.dropdown-menu li a {
  color: #1e1e1e;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
}

.dropdown-menu li .dataimg-arrow-down {
  color: #1e1e1e;
  width: 10px;
  height: 5px;
  position: absolute;
  top: 29px;
  right: 12px;
}

.dropdown-menu li.active > a:after {
  content: " ";
  border-bottom: 2px solid #1e1e1e;
  width: 100%;
  display: block;
  position: relative;
  top: 5px;
}

.dropdown-menu li.dropdown-title {
  height: 60px;
  padding: 0;
  position: relative;
}

.dropdown-menu li.dropdown-title > a:hover:after {
  content: " ";
  border-bottom: 2px solid #fff;
  width: 100%;
  display: block;
  position: relative;
  top: 5px;
}

.dropdown-menu li.dropdown-title > a, .dropdown-menu li.dropdown-title > span {
  box-sizing: border-box;
  text-transform: uppercase;
  padding: 24px 30px 19px 10px;
  display: block;
}

.dropdown-menu li.dropdown-title.no-childs > a, .dropdown-menu li.dropdown-title.no-childs > span {
  padding: 24px 8px 19px;
}

.dropdown-menu li.dropdown-show > ul li ul {
  background: #828282;
}

.dropdown-menu li.dropdown-show > ul li ul a {
  color: #fff;
}

.dropdown-menu li.dropdown-show > ul li ul a:after {
  border-color: #e6e6e6;
}

.dropdown-menu li .dataimg-arrow-right {
  color: #1e1e1e;
  width: 5px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: 12px;
}

.dropdown-menu li .dataimg-arrow-right path {
  fill: #1e1e1e;
}

.dropdown-menu li ul {
  background: #fff;
  width: 200px;
  padding: 0;
  position: absolute;
  top: 100%;
  box-shadow: 0 5px 6px rgba(0, 0, 0, .2);
}

.dropdown-menu li ul li {
  float: none;
  font-size: 16px;
  position: relative;
}

.dropdown-menu li ul li a {
  padding: 10px;
  display: block;
}

.dropdown-menu li ul li:last-child a {
  border-bottom: 0;
}

.dropdown-menu li ul li:hover {
  background: #dcdcdc;
}

.dropdown-menu li ul li:hover .dataimg-arrow-right path {
  fill: #1e1e1e;
}

.dropdown-menu li ul li:hover ul {
  color: #1e1e1e;
  background: #dcdcdc;
  position: absolute;
  top: 0;
  right: -100%;
  box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, .2);
}

.dropdown-menu li ul li:hover ul li a {
  color: #1e1e1e;
}

.dropdown-menu li ul li:hover ul li a:after {
  content: "";
  border-bottom: 1px solid #bebebe;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.dropdown-menu li ul li:hover ul li:hover {
  background: #bebebe;
}

.dropdown-menu li ul li:hover ul li:hover a:after {
  border-color: #bebebe;
}

.dropdown-menu li ul li:hover ul li:not(:first-child):hover a:before {
  content: "";
  border-bottom: 1px solid #bebebe;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.dropdown-menu li ul li:hover ul li:last-child a:after {
  border-bottom: 0;
}

.dropdown-menu--full-col {
  width: 100%;
}

.dropdown-menu--full-col ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.dropdown-menu--full-col li {
  width: 100%;
}

.dropdown-menu--full-col li.dropdown-title {
  background: #fff;
  border: 2px solid #e6e6e6;
  border-radius: 2px;
  height: 40px;
  padding: 0;
  position: relative;
}

.dropdown-menu--full-col li.dropdown-title > a {
  text-transform: none;
  color: #707070;
  padding: 0 30px 0 10px;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

.dropdown-menu--full-col li.dropdown-title > a > span > svg {
  position: absolute;
}

.dropdown-menu--full-col li.dropdown-title > a:hover:after {
  display: none;
}

.dropdown-menu--full-col li.dropdown-title .dataimg-arrow-down {
  top: 16px;
}

.dropdown-menu--full-col li ul {
  width: 100%;
  margin-top: 3px;
}

.dropdown-menu--full-col li ul li a {
  color: #1e1e1e;
  font-size: 13px;
}

.dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):hover > ul, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):active > ul, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):focus > ul {
  flex-flow: wrap;
  width: 260px;
  display: flex;
}

.dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):hover > ul, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):active > ul, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):focus > ul {
  flex-flow: wrap;
  width: 260px;
  display: flex;
}

.dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):hover > ul li, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):active > ul li, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):focus > ul li {
  float: left;
  flex-basis: 50%;
  width: auto;
}

.dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):hover > ul li, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):active > ul li, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):focus > ul li {
  float: left;
  flex-basis: 50%;
  width: auto;
}

.dropdown-menu .football.dropdown-title > a:hover {
  border-color: #00a442;
}

.dropdown-menu .hockey.dropdown-title > a:hover {
  border-color: #325edc;
}

.dropdown-menu .tennis.dropdown-title > a:hover {
  border-color: #ff6000;
}

.dropdown-menu:after, .dropdown-menu:before {
  content: "";
  clear: both;
  display: table;
}

a.additional-value-container {
  color: #aaa;
  float: left;
  width: auto;
  margin-top: 5px;
  display: block;
}

a.additional-value-container .additional-value {
  float: left;
  clear: both;
  font-size: 30px;
  line-height: 33px;
}

a.additional-value-container .additional-description {
  float: left;
  clear: both;
}

a.additional-value-container span {
  font-size: 13px;
  font-weight: 400;
}

a.additional-value-container .additional-value-small {
  float: left;
  clear: none;
  margin-right: 3px;
  font-size: 13px;
}

a.additional-value-container .additional-description-small {
  clear: none;
}

a.additional-value-container .icon {
  float: left;
  width: 5px;
  height: 9px;
  margin: 4px;
}

a.additional-value-container path {
  fill: #aaa;
}

.caption-arrow {
  float: left;
  background: #fff;
  width: 135px;
  height: 190px;
  margin-right: 30px;
  position: relative;
}

.caption-arrow .caption-title {
  color: #323232;
  text-align: left;
  width: 135px;
  padding: 44px 0 44px 30px;
  font-size: 26px;
  font-weight: 300;
}

.caption-arrow .arrow {
  border: 95px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: -30px;
}

.title-meta {
  color: #bebebe;
  font-size: 13px;
}

.box-container {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px 0;
}

.box-container.no-padding {
  padding: 0;
}

.box-container.no-margin {
  margin: 0;
}

.box-container.no-padding-bottom {
  padding-bottom: 0;
}

.box-container .box-content {
  padding: 0 15px;
}

.box-container p {
  font-size: 16px;
}

.box-container:after, .box-container:before {
  content: "";
  clear: both;
  display: table;
}

.box-container__title {
  text-transform: uppercase;
  color: #d21e1e;
  margin: 13px 9px 15px;
  font-family: roboto, arial, sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.box-container__title a {
  color: inherit;
}

@media screen and (min-width: 760px) {
  .box-container__title {
    font-size: 22px;
  }
}

.promo-bar {
  z-index: 1;
  background: #fff;
  min-width: 1080px;
  min-height: 40px;
  margin: 0 -20px;
  padding-top: 10px;
  position: relative;
}

.promo-bar > .wrapper {
  padding: 0;
}

.promo-bar ul {
  justify-content: space-between;
}

.promo-bar ul li:first-child {
  color: #d21e1e;
}

.promo-bar:after, .promo-bar:before {
  content: "";
  clear: both;
  display: table;
}

.link-large {
  margin: 30px 0;
  transition: all .5s;
}

.link-large a {
  padding: 20px;
  display: block;
}

.link-large a .title {
  float: left;
  color: #fff;
  width: 70%;
  margin-top: 5px;
  font-size: 22px;
  line-height: 30px;
}

.link-large a .button {
  float: right;
  color: #fff;
  text-align: center;
  border: 2px solid #fff;
  width: 30%;
  padding: 9px 60px 8px;
  font-size: 16px;
  font-weight: 700;
}

.link-large a:after, .link-large a:before {
  content: "";
  clear: both;
  display: table;
}

.link-large.link-flex a {
  display: flex;
}

.link-large.link-flex.link-flex--vertical a {
  flex-direction: column;
}

@media screen and (max-width: 760px) {
  .link-large a {
    padding: 10px;
  }

  .link-large a .title {
    float: none;
    width: 100%;
    font-size: 1.2em;
  }

  .link-large a .button {
    float: none;
    width: 80%;
    margin: 10px auto;
    padding: 9px;
  }
}

.paging {
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  display: block;
}

.paging button, .paging a.next, .paging a.prev {
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 9%;
  height: 60px;
  margin: 0;
  display: block;
}

.paging button:hover, .paging a.next:hover, .paging a.prev:hover {
  transition: all .2s;
}

.paging button.disabled, .paging a.next.disabled, .paging a.prev.disabled {
  opacity: .7;
  cursor: default;
}

.paging button .arrow-listing, .paging a.next .arrow-listing, .paging a.prev .arrow-listing {
  width: 13px;
  height: 26px;
}

.paging button .arrow-listing {
  margin: auto;
}

.paging a .arrow-listing {
  margin: 17px auto;
}

.paging .prev {
  float: left;
}

.paging .next {
  float: right;
}

.paging .paging-holder {
  float: left;
  text-align: center;
  background-color: #e6e6e6;
  width: 82%;
  height: 60px;
  margin: 0 auto;
  padding-left: 0;
  display: block;
}

.paging .paging-holder.bg-transparent {
  background: none;
}

.paging .paging-holder a {
  min-width: 29px;
  height: auto;
  padding: 16px;
  text-decoration: none;
  display: inline-block;
}

.paging .paging-holder .active, .paging .aktpage {
  font-weight: 700;
}

.paging--superlife .sport-color-bg {
  background-color: #02a555;
}

.paging--superlife .sport-color-hover:hover, .paging--superlife a.sport-color-hover:hover, .paging--superlife .sport-color.active {
  color: #02a555;
}

.paging:after, .paging:before {
  content: "";
  clear: both;
  display: table;
}

.old-isport-link {
  text-align: center;
  width: 1080px;
  margin: 0 auto;
  padding: 0 15px;
}

.old-isport-link a.link {
  font-size: 16px;
  display: inline-block;
}

.old-isport-link a.link .icon {
  float: right;
  margin-top: 8px;
}

.sms-box {
  min-height: 170px;
  margin-bottom: 20px;
  display: block;
}

.sms-box:last-of-type {
  margin-bottom: 0;
}

.sms-box .sms-text {
  color: #828282;
  word-wrap: break-word;
  font-size: 16px;
}

.sms-box .sms-up-bold {
  text-transform: uppercase;
  color: #323232;
  font-weight: 700;
}

.sms-box .sms-red {
  color: #d21e1e;
}

.sms-days-price {
  font-size: 0;
}

.sms-days-price .sms-days {
  color: #d21e1e;
  border-right: 1px solid #e6e6e6;
  font-size: 50px;
  font-weight: 700;
}

.sms-days-price .sms-price {
  color: #646464;
  padding-left: 10px;
  font-size: 50px;
}

.sms-days-price .sms-days:after, .sms-days-price .sms-price:before {
  content: " ";
}

.question-with-photo {
  padding: 20px;
}

.question-with-photo .photo-container {
  float: left;
  width: 70px;
}

.question-with-photo .info-container {
  float: left;
  width: calc(100% - 70px);
  padding: 5px 0 0 10px;
  font-size: 22px;
  font-weight: 700;
}

.question-with-photo .info-container .form-field {
  margin: 5px 0 0;
}

.question-with-photo:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.question-with-photo.can-vote {
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 0;
  transition: background-color .5s;
}

.question-with-photo.can-vote .photo-container, .question-with-photo.can-vote .info-container {
  cursor: pointer;
}

.question-with-photo.can-vote:nth-of-type(odd) {
  background-color: #fff;
}

.question-with-photo.can-vote:hover {
  background-color: #fdf2f2;
}

.question-with-photo .votes-container {
  float: right;
  font-size: 30px;
  font-weight: 300;
}

.question-with-photo:after, .question-with-photo:before {
  content: "";
  clear: both;
  display: table;
}

.league-name {
  text-transform: uppercase;
  font-size: .7em;
  font-weight: 700;
}

.round-pic {
  text-align: center;
  width: 78px;
  font-family: Roboto Slab, Times New Roman, serif;
  font-weight: 700;
  display: inline-block;
}

.round-pic img {
  border-radius: 78px;
  margin-bottom: 5px;
  overflow: hidden;
}

.round-pic:hover a {
  color: #00a442;
  text-decoration-color: #00a442;
}

.text-bordered {
  text-align: center;
  color: #d21e1e;
  border: 2px solid #d21e1e;
  border-width: 2px 0;
  width: 100%;
  margin: 0 0 15px;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 2.5;
}

.text-bordered a {
  color: #d21e1e;
}

.box-content-article {
  background-color: #fff;
  padding: 5px;
}

.text-dot-grey {
  color: #828282;
  margin-top: -7px;
  margin-left: 7px;
  display: inline-block;
}

.text-dot-grey:before {
  content: ". ";
  vertical-align: bottom;
  font-size: 25px;
}

.liga-videos-wrapp {
  width: 50px;
  margin: 0 8px;
  position: relative;
}

.liga-videos-wrapp.het {
  margin-left: 0;
  padding-left: 20px;
}

.liga-videos-wrapp .video-count {
  text-align: center;
  color: #fff;
  background: #d21e1e;
  border: 2px solid #fff;
  border-radius: 36px;
  width: 34px;
  height: 34px;
  padding-top: 5px;
  font-weight: 700;
  position: absolute;
  top: -9px;
  right: -12px;
}

.subscription-title {
  margin: 0 15px 15px;
}

.subscription-issue-detail-left {
  float: left;
  margin: 0 0 0 15px;
}

.subscription-issue-detail-left img {
  height: 275px;
}

.subscription-icons {
  float: right;
  text-align: center;
  height: 270px;
  margin: 0 28px 0 0;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 5px;
}

.subscription-icons .liga-zive {
  margin-top: 9px;
}

.subscription-icons .goly-z-ligy {
  position: absolute;
  bottom: 0;
}

.subscription-icons .podcasty {
  margin-top: 9px;
}

.subscription-link-left {
  float: left;
  margin: 30px 0 30px 15px;
}

.subscription-link-right {
  float: right;
  margin: 30px 8px 30px 0;
}

.siteversion {
  background-color: #fff;
  width: 1050px;
  margin: 20px auto 0;
  padding: 0 15px;
  overflow: hidden;
}

.siteversion hr {
  width: auto;
}

.siteversion .link-with-icon {
  clear: both;
  color: #1e1e1e;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.siteversion .link-with-icon:hover {
  color: #d21e1e;
  text-decoration: none;
}

.siteversion .link-with-icon:hover .icon path {
  fill: #d21e1e;
  transition: fill .2s;
}

.siteversion .link-with-icon.no-icon {
  padding-right: 0;
  line-height: 100%;
}

.siteversion .link-with-icon.icon-left .icon {
  float: left;
  margin-right: 10px;
}

.siteversion .link-with-icon.icon-right .icon {
  float: right;
  margin-left: 10px;
}

.siteversion .link-with-icon .icon {
  width: 15.1px;
  height: 15px;
  display: inline-block;
}

.siteversion .link-with-icon .icon.dataimg-desktop {
  width: 22px;
  margin-top: -3px;
}

.siteversion .link-with-icon .icon.dataimg-mobil {
  margin-top: -5px;
}

.siteversion .link-with-icon .icon.icon-menu {
  top: 0;
  right: 0;
}

.siteversion .link-with-icon .menu-icon-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: 0;
}

.isport-premium {
  background: #cca550;
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.isport-premium.abs {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.isport-premium.small {
  width: 115px;
  height: 32px;
}

.isport-premium.smaller {
  width: 85px;
  height: 24px;
}

.isport-premium.smallest {
  width: 73px;
  height: 20px;
}

.isport-premium.smallest.alt {
  width: 75px;
}

.isport-premium.mid {
  width: 137px;
  height: 32px;
}

.isport-premium.large {
  width: 138px;
  height: 38px;
}

.isport-premium .dataimg-isport-premium {
  min-height: 1px;
  display: block;
}

.logo-fortuna-liga {
  width: 40px;
  height: 40px;
  margin-top: 13px;
  margin-left: 10px;
}

.four-col.right-col .link-big .dataimg-arrow-right {
  position: relative;
}

.four-col.right-col .link-big .dataimg-arrow-right svg, .four-col.right-col .box-container .align-center .link .dataimg-arrow-right svg {
  position: absolute;
}

.clear {
  clear: both;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-0 {
  margin: 0;
}

.margin-top {
  margin-top: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 15px;
}

.margin-left {
  margin-left: 15px;
}

.margin-left-70 {
  margin-left: 70px;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-double {
  margin-bottom: 30px;
}

.padding-top {
  padding-top: 15px;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-left {
  padding-left: 15px;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-left-mobile {
  margin-left: 2%;
}

.margin-right-mobile {
  margin-right: 2%;
}

.bg-color-row {
  background-color: #fff;
}

.bg-color-row:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.text-color-light {
  color: #fff;
}

.text-color-grey1 {
  color: #323232;
}

.text-color-red {
  color: #d21e1e;
}

.text-color-light-grey {
  color: #e6e6e6;
}

.text-grey-middle {
  color: #828282;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-basic {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-basic {
  font-family: Roboto, arial, sans-serif;
}

.font-size-7, .font-size-6 {
  font-size: 9px;
}

.font-size-4 {
  font-size: 22px;
}

.border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.border-bottom-none, .border-bottom-none .title-section.border-bottom {
  border-bottom: none;
}

.inline-block {
  display: inline-block;
}

.border-top {
  border-top: 1px solid #dcdcdc;
}

.border-top-2px {
  border-top: 2px solid #dcdcdc;
}

.border-top-3px {
  border-top: 3px solid #dcdcdc;
}

.border-top-red {
  border-top-color: #d21e1e;
}

.border-right {
  border-right: 1px solid #dcdcdc;
}

.border-none {
  border: 0;
}

.flag-wrapp {
  position: absolute;
  top: 0;
  left: 0;
}

.flag-wrapp div {
  width: 17px;
  height: 15px;
}

.display-none {
  display: none;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
}

hr.divider {
  height: 1px;
}

hr.divider--big {
  height: 2px;
}

.superlife-border-top-strong {
  border-top: 3px solid #02a555;
}

.superlife.space-sides {
  padding: 0 2%;
}

.superlife .title-section .main-title {
  color: #02a555;
}

.table-default-small, .main-article .content table, .table-default-big {
  width: 100%;
}

.table-default-small thead, .main-article .content table thead, .table-default-big thead {
  border-bottom: 1px solid #e6e6e6;
}

.table-default-small thead tr, .main-article .content table thead tr, .table-default-big thead tr {
  color: #aaa;
  background-color: #fff;
}

.table-default-small tbody, .main-article .content table tbody, .table-default-big tbody {
  border-bottom: 1px solid #e6e6e6;
}

.table-default-small tbody tr:nth-child(odd), .main-article .content table tbody tr:nth-child(odd), .table-default-big tbody tr:nth-child(odd) {
  background-color: #fff;
}

.table-default-small tbody tr:nth-child(2n), .main-article .content table tbody tr:nth-child(2n), .table-default-big tbody tr:nth-child(2n) {
  background-color: #f0f0f0;
}

.table-default-big td, .table-default-big th {
  padding: 10px;
}

.table-default-big td .description-small, .table-default-big th .description-small {
  font-size: 13px;
}

.table-default-small, .main-article .content table {
  font-size: 12px;
}

.table-default-small td, .table-default-small th, .main-article .content table td, .main-article .content table th {
  height: 30px;
  padding: 4px;
}

.main-article .content table {
  margin-bottom: 20px;
}

.main-article .content table.table-league th, .main-article .content table.table-league td {
  padding: 4px 2px;
}

.main-article .content table.table-league th.logo, .main-article .content table.table-league td.logo {
  padding: 0;
}

table.table-basic tbody tr th, table.table-basic tbody tr td {
  vertical-align: top;
  width: 26.6%;
  font-size: 16px;
  font-weight: 400;
}

table.table-basic tbody tr th:first-child, table.table-basic tbody tr td:first-child {
  vertical-align: middle;
  width: 21.3%;
  font-weight: 700;
}

table.table-basic tbody tr td {
  border-bottom: 2px solid #dcdcdc;
  padding: 15px 15px 15px 0;
}

table.table-basic tbody tr th {
  border-bottom: 2px solid #1e1e1e;
  padding: 20px 5px;
  font-size: 13px;
  font-weight: 700;
}

.table-league th, .table-league td {
  text-align: center;
}

.table-league .order {
  max-width: 26px;
  padding-right: 0;
}

.table-league .teamName {
  text-align: left;
}

.table-league .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-big td.logo {
  padding: 5px 0;
  line-height: 1;
}

.table-league.table-default-big td.logo img {
  max-height: 25px;
}

.table-league.table-default-big td .position {
  float: left;
  text-align: right;
  width: 28px;
  padding-right: 3px;
  display: block;
}

.table-league.table-default-big th:first-child, .table-league.table-default-big td:first-child {
  text-align: left;
}

.table-league.table-default-big.table-league--color tr td:first-child {
  margin: 1px 0;
}

.table-league.table-default-big.table-league--color tr td:first-child strong {
  margin-left: 18px;
  font-size: 16px;
  line-height: 38px;
}

.table-league.table-default-big.table-league--color tr .position, .table-league.table-default-big.table-league--color tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 30px;
  height: 38px;
  padding: 0;
  font-size: 15px;
  line-height: 38px;
  display: inline-block;
}

.table-league.table-default-big.table-league--color tr .teamName {
  padding-left: 6px;
  font-size: 16px;
}

.table-league.table-default-big.table-league--color tr .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-big.table-league--color tr:nth-child(n+7) .position, .table-league.table-default-big.table-league--color tr:nth-child(n+7) .order {
  background: #325edc;
}

.table-league.table-default-big.table-league--color tr:nth-child(n+11) .position, .table-league.table-default-big.table-league--color tr:nth-child(n+11) .order {
  background: #d21e1e;
}

.table-league.table-default-big.table-league--color-hockey tr:nth-child(n+11) .position, .table-league.table-default-big.table-league--color-hockey tr:nth-child(n+11) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-big.table-league--color-hockey tr:nth-child(14) .position, .table-league.table-default-big.table-league--color-hockey tr:nth-child(14) .order {
  color: #fff;
  background: #d21e1e;
}

.table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+5) .position, .table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+13) .position, .table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small caption.title-section {
  display: table-caption;
}

.table-league.table-default-small td .position {
  float: left;
  text-align: center;
  width: 19px;
  padding: 0;
}

.table-league.table-default-small td .teamName {
  line-height: inherit;
  width: 101px;
  margin-left: 22px;
  display: block;
}

.table-league.table-default-small td.logo {
  width: 25px;
  padding: 0;
  line-height: 1;
}

.table-league.table-default-small td.logo img {
  max-height: 25px;
}

.table-league.table-default-small td.double-line-content .position {
  line-height: 30px;
}

.table-league.table-default-small td.score {
  padding: 4px 2px;
}

.table-league.table-default-small th:first-child {
  text-align: left;
  padding-left: 4px;
}

.table-league.table-default-small td:first-child {
  text-align: left;
  padding-left: 0;
}

.table-league--color, .table-league.table-default-small.table-league--color {
  font-size: 12px;
}

.table-league--color tr td, .table-league.table-default-small.table-league--color tr td {
  padding: 1px 2px;
}

.table-league--color tr td:first-child, .table-league.table-default-small.table-league--color tr td:first-child {
  padding: 1px 0;
}

.table-league--color tr td.logo, .table-league.table-default-small.table-league--color tr td.logo {
  padding: 0;
}

.table-league--color tr .position, .table-league--color tr .order, .table-league.table-default-small.table-league--color tr .position, .table-league.table-default-small.table-league--color tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 22px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
}

.table-league--color tr .teamName, .table-league.table-default-small.table-league--color tr .teamName {
  padding-left: 6px;
  line-height: 30px;
}

.table-league--color tr .teamName > a, .table-league.table-default-small.table-league--color tr .teamName > a {
  text-decoration: underline;
}

.table-league--color tr:nth-child(n+7) .position, .table-league--color tr:nth-child(n+7) .order, .table-league.table-default-small.table-league--color tr:nth-child(n+7) .position, .table-league.table-default-small.table-league--color tr:nth-child(n+7) .order {
  background: #325edc;
}

.table-league--color tr:nth-child(n+11) .position, .table-league--color tr:nth-child(n+11) .order, .table-league.table-default-small.table-league--color tr:nth-child(n+11) .position, .table-league.table-default-small.table-league--color tr:nth-child(n+11) .order {
  background: #d21e1e;
}

.table-league.table-default-small.table-league--color-international {
  font-size: 12px;
}

.table-league.table-default-small.table-league--color-international tr td {
  padding: 1px 2px;
}

.table-league.table-default-small.table-league--color-international tr td:first-child {
  padding: 1px 0;
}

.table-league.table-default-small.table-league--color-international tr td.logo {
  padding: 0;
}

.table-league.table-default-small.table-league--color-international tr .position, .table-league.table-default-small.table-league--color-international tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 22px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
}

.table-league.table-default-small.table-league--color-international tr .teamName {
  padding-left: 6px;
  line-height: 30px;
}

.table-league.table-default-small.table-league--color-international tr .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+7) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+7) .order {
  background: #00a442;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+9) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+9) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+25) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+25) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(n+5) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(n+13) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(14) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(14) .order {
  color: #fff;
  background: #ff6000;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(15) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(15) .order {
  color: #fff;
  background: #d21e1e;
}

.table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+5) .position, .table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+13) .position, .table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.legend-table-league-color {
  margin: 14px 0 0;
  padding: 0;
}

.legend-table-league-color li {
  margin: 0 18px 14px 0;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.legend-table-league-color li span {
  float: left;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
}

.legend-table-league-color li .square-green {
  background-color: #00a442;
}

.legend-table-league-color li .square-blue {
  background-color: #325edc;
}

.legend-table-league-color li .square-red {
  background-color: #d21e1e;
}

.legend-table-league-color li .square-orange {
  background-color: #ff6000;
}

.table-synottip {
  margin-bottom: 40px;
}

.table-synottip thead {
  border-bottom: 2px solid #e6e6e6;
}

.table-synottip thead tr th {
  color: #aaa;
  padding: 10px;
  font-weight: 400;
}

.table-synottip tbody {
  border-bottom: 2px solid #e6e6e6;
}

.table-synottip tbody tr td {
  padding: 7px;
}

.table-synottip tbody tr td:not(.sport-color):last-child {
  color: #1e1e1e;
}

.button-secondary, .main-button, .button-main {
  text-align: center;
  border: 0;
  border-radius: 2px;
  width: 240px;
  padding: 10px 0;
  font-weight: bolder;
  line-height: 20px;
  transition: all .5s;
  display: block;
  position: relative;
}

.button-secondary:disabled, .main-button:disabled, .button-main:disabled {
  color: #aaa;
  background: #dcdcdc;
}

.button-secondary:focus, .main-button:focus, .button-main:focus, .button-secondary:active, .main-button:active, .button-main:active, .button-secondary:hover, .main-button:hover, .button-main:hover {
  color: #fff;
  outline: 0;
}

.button-secondary .info, .main-button .info, .button-main .info {
  float: right;
  background: #fff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 40px;
  margin: -10px -10px -10px 10px;
  padding: 0 10px;
  line-height: 40px;
  transition: all .5s;
  display: block;
  position: relative;
}

.main-button, .button-main {
  color: #fff;
  background: #d21e1e;
}

.main-button:hover:enabled, .main-button:focus, .button-main:hover:enabled, .button-main:focus {
  color: #fff;
  background: #791111;
  text-decoration: none;
}

.main-button.half-divided, .button-main.half-divided {
  float: left;
  margin: 0 45px;
}

.main-button.half-divided.button-video .dataimg-play, .button-main.half-divided.button-video .dataimg-play {
  float: right;
  width: 20px;
  display: block;
  position: relative;
  top: 0;
  right: 20px;
}

.button-secondary {
  color: #fff;
  background: #aaa;
}

.button-secondary:hover:enabled {
  color: #fff;
  background: #828282;
  text-decoration: none;
}

.button-with-icon {
  background-color: #d21e1e;
  width: auto;
  padding: 10px;
  display: inline-block;
}

.button-with-icon .icon {
  float: left;
  width: 20px;
  height: 20px;
  position: relative;
}

.button-with-icon .icon path {
  fill: #fff;
}

.button-with-icon .label {
  margin-left: 10px;
}

.button-facebook {
  color: #fff;
  background-color: #3b5998;
}

.button-facebook .info {
  color: #3b5998;
  border: 2px solid #3b5998;
}

.button-facebook:hover, .button-facebook:hover:enabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

.button-facebook:hover .info {
  color: #3b5998;
  border-color: #3b5998;
}

.button-twitter {
  background-color: #50abf1;
}

.button-twitter .icon {
  top: 2px;
}

.button-twitter .info {
  color: #50abf1;
  border: 2px solid #50abf1;
}

.button-twitter:hover, .button-twitter:hover:enabled {
  background-color: #50abf1;
}

.button-twitter:hover .info {
  color: #50abf1;
  border-color: #50abf1;
}

.button-with-info {
  text-align: left;
  min-width: 240px;
}

.button-menu span {
  background: #d21e1e;
  border-radius: 2px;
  padding: 5px;
  display: inline;
}

.button-small {
  color: #fff;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  padding: 5px;
  font-size: 9px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.button-small.button-gray {
  background: #aaa;
}

.tipsport-tv-url {
  color: #0197f4;
  max-height: 25px;
  margin: 0 auto;
  padding-top: 2px;
  display: block;
}

.tipsport-tv-url .match-online .gray, .tipsport-tv-url .match-online .icone, .tipsport-tv-url .match-online .tipsport {
  float: left;
}

.tipsport-tv-url .match-online .gray {
  color: #828282;
  width: 92px;
}

.tipsport-tv-url .match-online .icone {
  width: 26px;
  height: auto;
  margin: 4px 8px;
  display: inline-block;
}

.tipsport-tv-url .match-online .tipsport, .tipsport-tv-url .match-online .match-online-text .tipsport {
  color: #0197f4;
}

.tipsport-tv-url .match-online:after, .tipsport-tv-url .match-online:before {
  content: "";
  clear: both;
  display: table;
}

.list-score {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  position: relative;
}

.list-score .datetime-container {
  border-right: 1px solid #dcdcdc;
  width: 20%;
  padding: 7px;
  font-size: 13px;
  font-weight: 700;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.list-score .datetime-container .flag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-score .datetime-container--show {
  display: block;
}

.list-score .league-name, .list-score .phase-name {
  text-align: center;
  text-transform: uppercase;
  color: #828282;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  top: 9px;
  left: 140px;
}

.list-score .match-container .match-bet-cover a:hover {
  text-decoration: none;
}

.list-score .match-container .match-bet-cover a img.bets {
  width: 50px;
  height: auto;
  margin: 2px auto;
  display: block;
}

.list-score .match-container .match-bet-cover.with-match-online {
  width: calc(50% + 27px);
  margin-left: calc(50% - 27px);
}

.list-score .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px 6px 4px 0;
}

.list-score .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin-top: 0;
  font-weight: normal;
}

.list-score .match-container:after, .list-score .match-container:before {
  content: "";
  clear: both;
  display: table;
}

.list-score .team-container {
  float: left;
  width: 44%;
}

.list-score .team-container .team-name {
  width: 80%;
  margin-top: 21px;
  font-size: 22px;
  display: block;
  position: relative;
}

.list-score .team-container .logo-container {
  text-align: center;
  width: 20%;
  margin-top: 6px;
}

.list-score .team-container .logo-container__lazy {
  width: 24px;
  height: 24px;
}

.list-score .team-container .logo-container__lazy picture {
  border-radius: 25px;
  margin: 2px 0 0;
}

.list-score .team-container .logo-container img, .list-score .team-container .logo-container svg {
  max-width: 100%;
  height: auto;
}

.list-score .team-container.team-home .logo-container {
  float: left;
}

.list-score .team-container.team-home .team-name {
  float: left;
  padding-left: 5px;
}

.list-score .team-container.team-away .logo-container {
  float: right;
}

.list-score .team-container.team-away .team-name {
  float: right;
  text-align: right;
  padding-right: 5px;
}

.list-score .score-container {
  float: left;
  text-align: center;
  width: 12%;
}

.list-score .score-container .score {
  margin-top: 7px;
  font-size: 30px;
  font-weight: 300;
}

.list-score .score-container .score span {
  font-size: 22px;
  position: relative;
  top: -4px;
}

.list-score .score-container .score .score-pen {
  letter-spacing: 1px;
  font-size: 9px;
}

.list-score .score-container .score .score-pen-ot, .list-score .score-container .score .score-pen-sn {
  height: 20px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.list-score .score-container .score .score-pen-ot:before, .list-score .score-container .score .score-pen-sn:before {
  z-index: 300;
  letter-spacing: 1px;
  margin-left: 2px;
  font-size: 9px;
  display: inline-block;
  position: absolute;
  bottom: 6px;
}

.list-score .score-container .score .score-pen-ot:before {
  content: "P";
}

.list-score .score-container .score .score-pen-sn:before {
  content: "SN";
}

.list-score .score-container .date-container {
  margin-top: 16px;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .play-container {
  width: 60px;
  height: 16px;
  margin: auto;
}

.list-score .score-container .play-container .dataimg-play {
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 1px;
}

.list-score .score-container .play-container .flag-video {
  color: #d21e1e;
  float: left;
  padding-left: 2px;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .flag-online {
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .flag-paid {
  color: #d21e1e;
  font-size: 9px;
  font-weight: 700;
  display: none;
  position: absolute;
  bottom: 0;
}

.list-score .score-container .periods, .list-score .score-container .bets {
  font-size: 13px;
}

.list-score .score-container .series-score {
  margin-top: 5px;
  font-size: 13px;
}

.list-score .series-score-container {
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}

.list-score .series-score-container .series-score {
  font-size: 12px;
}

.list-score .series-score-container:after, .list-score .series-score-container:before {
  content: "";
  clear: both;
  display: table;
}

.list-score.status-fin .score-container .date-container, .list-score.status-fin .score-container .flag-online, .list-score.status-fin .score-container .flag-paid {
  display: none;
}

.list-score.status-fin .score-container .score {
  margin-top: 16px;
}

.list-score.status-sched .score-container .score, .list-score.status-sched .score-container .flag-online {
  display: none;
}

.list-score.status-sched .link--online .icon .dataimg-play {
  display: block;
}

.list-score.status-sched .link--online .icon .dataimg-play svg {
  height: 16px;
}

.list-score.status-live .score-container .date-container {
  display: none;
}

.list-score.status-live .score-container .score {
  color: #d21e1e;
}

.list-score.dark {
  border-color: #646464;
}

.list-score.dark .team-container .team-name, .list-score.dark .score-container {
  color: #fff;
}

.list-score.formule1 .datetime-container {
  width: 10%;
}

.list-score.formule1 .datetime-container:last-of-type {
  left: inherit;
  border-left: 1px solid #dcdcdc;
  width: 10%;
  right: 0;
}

.list-score .match-online {
  width: 232px;
  font-size: 13px;
  font-weight: bold;
}

.list-score .link--online {
  color: #d21e1e;
  margin-top: 20px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
}

.list-score .link--online .link--left {
  z-index: 300;
  position: relative;
}

.list-score .link--online.link--right {
  margin-top: 0;
}

.list-score .link--online .icon .dataimg-play, .list-score .link--online .icon .dataimg-arrow-right {
  float: right;
  margin-left: 3px;
}

.list-score .link--online .icon .dataimg-arrow-right {
  margin-top: 3px;
}

.list-score .link--online .icon .dataimg-arrow-right svg {
  height: 10px;
}

.list-score .link--online .icon .dataimg-arrow-right path {
  fill: #d21e1e;
}

.list-score .link--online.link--red {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  margin-top: 17px;
  margin-right: 6px;
  padding: 0 7px;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;
  text-decoration: none;
  display: flex;
}

.list-score .link--online.link--red .icon .dataimg-icon-play {
  float: right;
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

@media only screen and (max-width: 376px) {
  .list-score .link--online.link--red .icon .dataimg-icon-play {
    width: 7px;
    height: 7px;
    margin-top: 3px;
    margin-left: 3px;
    line-height: 12px;
  }
}

@media only screen and (min-width: 425px) {
  .list-score .link--online.link--red {
    width: 70px;
    height: 27px;
  }
}

@media only screen and (max-width: 425px) {
  .list-score .link--online.link--red {
    margin-top: 20px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 321px) {
  .list-score .link--online.link--red {
    margin-top: 4px;
    margin-right: 3px;
  }
}

.list-score .link--online.link--red-small {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  height: 20px;
  margin-top: 3px;
  margin-right: 6px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  display: flex;
}

.list-score .link--online.link--red-small .icon .dataimg-icon-play {
  float: right;
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

.list-score .link--right, .list-score .link--right-b {
  position: absolute;
  top: 0;
  right: 0;
}

.list-score .link-external {
  cursor: pointer;
}

.list-score:after, .list-score:before {
  content: "";
  clear: both;
  display: table;
}

.list-score-no-link {
  padding-bottom: 10px;
}

.list-score-no-link:last-of-type .list-score {
  border-bottom: 0;
}

a.list-score-link:nth-of-type(2n) .list-score {
  background-color: #f0f0f0;
}

a.list-score-link:nth-of-type(2n) .list-score.dark {
  background-color: #1e1e1e;
}

a.list-score-link .list-score-fortuna {
  background-color: #f0f0f0;
}

a.list-score-link:hover {
  text-decoration: none;
}

a.list-score-link:hover .list-score {
  background-color: #fdf2f2;
  transition: background-color .5s;
}

a.list-score-link:hover .list-score .score-container, a.list-score-link:hover .list-score .series-score-container, a.list-score-link:hover .list-score .date-container .date, a.list-score-link:hover .list-score .date-container .time {
  color: #d21e1e;
  transition: color .2s;
}

a.list-score-link:hover .list-score.dark .score-container {
  color: #791111;
}

a.list-score-link:hover .list-score.dark .score-container .score, a.list-score-link:hover .list-score.dark .date-container .date, a.list-score-link:hover .list-score.dark .date-container .time {
  color: #791111;
  transition: color .2s;
}

a.list-score-link.dark {
  border-color: #646464;
}

a.list-score-link:after, a.list-score-link:before {
  content: "";
  clear: both;
  display: table;
}

a.list-score-link .series-score, a.list-score-link:hover .series-score {
  text-decoration: none !important;
}

.list-score-structured-wapper:nth-of-type(2n) a.list-score-link .list-score, .list-score-structured-wapper:nth-of-type(2n) a.list-score-link:hover .list-score {
  background-color: #f0f0f0;
}

.list-score-structured-wapper:last-of-type a.list-score-link .list-score {
  border-bottom: 1px solid #dcdcdc;
}

.list-score-structured-wapper:first-of-type a.list-score-link:first-of-type .list-score-tennis, .list-score-structured-wapper a.list-score-link .list-score.list-score-middle {
  border-top: 1px solid #dcdcdc;
}

.list-score-structured-wapper a.list-score-link .list-score.list-score-middle .team-container .team-name {
  padding: 0 10px 0 5px;
  font-size: 13px;
}

.list-score-structured-wapper a.list-score-link:first-of-type .list-score-tennis {
  border-top: none;
}

.list-score-structured-wapper a.list-score-link:hover .list-score {
  background: inherit;
}

.list-score-structured-wapper .datetime-container--right-col {
  width: 33%;
}

.list-score-structured-wapper .list-score.list-score-other .event-container--right-col {
  width: 67%;
}

@media screen and (max-width: 760px) {
  .list-score .datetime-container {
    text-align: center;
    border-right: 0;
    width: 100%;
    padding: 7px 0 0;
    font-size: 9px;
    font-weight: 700;
    position: relative;
  }

  .list-score .datetime-container .flag {
    position: inherit;
    right: inherit;
    top: inherit;
    margin: 0 auto 3px;
  }

  .list-score .team-container {
    height: auto;
  }

  .list-score .team-container .team-name {
    width: 80%;
    font-size: 18px;
  }

  .list-score .team-container.team-home .logo-container, .list-score .team-container.team-away .logo-container {
    float: right;
    width: 20%;
  }

  .list-score .match-container .match-bet-cover a:hover {
    text-decoration: none;
  }

  .list-score .match-container .match-bet-cover a img.bets {
    width: 50px;
    height: auto;
    margin: 2px auto;
    display: block;
  }

  .list-score .match-container .match-bet-cover.with-match-online {
    margin: 2px auto 4px;
  }

  .list-score .match-container .match-bet-cover.with-match-online a img.bets {
    margin: 2px auto;
  }

  .mobile .list-score .league-name, .mobile .list-score .phase-name {
    text-align: center;
    position: static;
  }
}

.art-object.match-overview .list-score .match-container .team-container {
  width: 42%;
}

.art-object.match-overview .list-score .match-container .team-container .team-name {
  margin-top: 12px;
  margin-bottom: 12px;
}

.art-object.match-overview .list-score .match-container .team-container .logo-container {
  margin-top: 6px;
}

.art-object.match-overview .list-score .score-container {
  width: 16%;
}

.art-object.match-overview .list-score .score-container .score {
  margin-top: 7px;
  font-size: 26px;
}

.art-object.match-overview .list-score .score-container .date-container {
  margin-top: 12px;
  font-size: 9px;
  font-weight: 700;
}

.four-col .list-score .datetime-container .flag {
  position: relative;
  top: 2px;
  right: 0;
}

.list-score.list-score-small {
  min-height: 30px;
}

.list-score.list-score-small.date-show .datetime-container {
  display: block;
}

.list-score.list-score-small.date-show .match-container {
  float: right;
  width: 80%;
}

.list-score.list-score-small .flag-live {
  color: #d21e1e;
  font-weight: 700;
}

.list-score.list-score-small .team-container {
  width: 42%;
  height: 30px;
}

.list-score.list-score-small .team-container .team-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1e1e1e;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 700;
  overflow: hidden;
}

.list-score.list-score-small .team-container .team-name.team-looser {
  color: #828282;
}

.list-score.list-score-small .team-container .logo-container {
  margin-top: 0;
}

.list-score.list-score-small .team-container .logo-container img {
  max-width: 30px;
  height: auto;
}

.list-score.list-score-small .team-container .logo-container .dataimg-teamlogo-placeholder {
  max-width: 30px;
  height: auto;
  margin: 0 auto;
}

.list-score.list-score-small .team-container.team-home .team-name {
  text-align: right;
  padding-right: 10px;
}

.list-score.list-score-small .team-container.team-away .team-name {
  text-align: left;
  width: 80%;
  padding-left: 10px;
}

.list-score.list-score-small .play-container {
  width: 15px;
  margin: 0;
  position: absolute;
  top: 5px;
  right: 5px;
}

.list-score.list-score-small .play-container .dataimg-play {
  float: left;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  right: 5px;
}

.list-score.list-score-small .play-container .flag-video {
  display: none;
}

.list-score.list-score-small .score-container {
  width: 16%;
}

.list-score.list-score-small .score-container .score {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
}

.list-score.list-score-small .score-container .score span {
  font-size: 13px;
  top: -2px;
}

.list-score.list-score-small .score-container .score .score-pen {
  letter-spacing: 1px;
  font-size: 9px;
}

.list-score.list-score-small .score-container .score .score-pen-ot, .list-score.list-score-small .score-container .score .score-pen-sn {
  height: 20px;
  line-height: 1;
}

.list-score.list-score-small .score-container .score .score-pen-ot:before, .list-score.list-score-small .score-container .score .score-pen-sn:before {
  bottom: 6px;
}

.list-score.list-score-small .score-container .flag-online {
  display: none;
}

.list-score.list-score-small .score-container .date-container {
  margin-top: 9px;
}

.list-score.list-score-small .score-container .date-container .date {
  display: none;
}

.list-score.list-score-small .score-container .date-container .time {
  color: #828282;
  font-size: 9px;
}

.list-score.list-score-small .score-container .bets {
  color: #d21e1e;
  text-transform: uppercase;
  margin: 10px 0 5px;
  font-size: 9px;
  font-weight: 700;
}

.list-score.list-score-small .score-container .bets:hover {
  text-decoration: underline;
}

.list-score.list-score-small .score-container .play-container {
  display: none;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online {
  width: 100%;
  margin: 2px auto 6px;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px auto;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin: 4px auto 0;
  font-weight: normal;
}

.list-score.list-score-small.status-fin .flag-live {
  display: none;
}

.list-score.list-score-small.status-fin .team-container, .list-score.list-score-small.status-sched .team-container {
  height: 30px;
}

.list-score.list-score-small.status-sched .flag-live, .list-score.list-score-small.status-sched .dataimg-play {
  display: none;
}

.list-score.list-score-small.status-live .team-container {
  height: 30px;
}

.list-score.list-score-small.status-live .dataimg-play {
  display: none;
}

.list-score.list-score-small:nth-of-type(2n) {
  background-color: #f0f0f0;
}

a.list-score-link:last-of-type .list-score {
  border-bottom: 1px solid #dcdcdc;
}

a.list-score-link:first-of-type .list-score {
  border-top: 1px solid #dcdcdc;
}

.flag-live {
  float: none !important;
}

.bets-text {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 150px;
}

@media screen and (max-width: 360px) {
  .mobile .list-score .score-container {
    width: 26%;
  }

  .mobile .list-score .team-container {
    width: 37%;
  }
}

@media screen and (max-width: 760px) {
  .list-score.list-score-small.date-show .match-container {
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .mobile .list-score .score-container.score-big .score {
    font-size: 13px;
  }

  .mobile .list-score .score-container.score-big .score span {
    font-size: 9px;
  }
}

.list-score.list-score-middle {
  border-top: 0;
  border-bottom: 0;
  min-height: 65px;
}

.list-score.list-score-middle .datetime-container {
  display: none;
}

.list-score.list-score-middle .phase-name {
  width: 100%;
  top: 5px;
  left: 0;
}

.list-score.list-score-middle .team-container {
  width: 38.5%;
  height: 65px;
}

.list-score.list-score-middle .team-container .logo-container {
  width: 32%;
  margin-top: 13px;
}

.list-score.list-score-middle .team-container .team-name {
  flex-direction: column;
  justify-content: center;
  width: 68%;
  height: 62px;
  margin-top: 0;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.list-score.list-score-middle .team-container.team-away .team-name {
  text-align: left;
  padding: 0 0 0 5px;
}

.list-score.list-score-middle .team-container.team-home .team-name {
  text-align: right;
  padding: 0 5px 0 0;
}

.list-score.list-score-middle .score-container {
  width: 23%;
  min-height: 59px;
  position: relative;
}

.list-score.list-score-middle .score-container.score-big .score {
  margin-top: 22px;
  font-size: 16px;
}

.list-score.list-score-middle .score-container.score-big .score span {
  font-size: 16px;
  position: relative;
  top: -1px;
}

.list-score.list-score-middle .score-container .score {
  margin-top: 14px;
}

.list-score.list-score-middle .score-container .score-pen {
  font-size: 9px;
  font-weight: bold;
}

.list-score.list-score-middle .score-container .score-pen-ot:before, .list-score.list-score-middle .score-container .score-pen-sn:before {
  line-height: inherit;
  margin-left: 3px;
  bottom: -5px;
}

.list-score.list-score-middle .score-container .flag {
  font-size: 9px;
  font-weight: 700;
  position: relative;
  top: -43px;
}

.list-score.list-score-middle .score-container .flag-end {
  text-align: center;
  text-transform: uppercase;
  color: #aaa;
  width: 100%;
  position: absolute;
  top: 5px;
}

.list-score.list-score-middle .score-container .flag-paid {
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
}

.list-score.list-score-middle .score-container .date-container .date {
  font-weight: 400;
}

.list-score.list-score-middle.status-fin .score-container .flag-paid {
  display: none;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online {
  width: 100%;
  margin: 2px auto 6px;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px auto;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin: 4px auto 0;
  font-weight: normal;
}

.football .list-score.list-score-middle .score-container .score .score-pen {
  font-size: 13px;
  font-weight: bold;
}

.football .list-score.list-score-middle .score-container .score .score-pen-ot, .football .list-score.list-score-middle .score-container .score .score-pen-sn {
  height: auto;
  display: block;
}

.football .list-score.list-score-middle .score-container .score .score-pen-ot:before, .football .list-score.list-score-middle .score-container .score .score-pen-sn:before {
  bottom: inherit;
  text-align: center;
  width: 39px;
  margin-left: 0;
  font-size: 13px;
  font-weight: bold;
  left: 0;
}

a.list-score-link .list-score.list-score-middle {
  border-top: 0;
  border-bottom: 0;
}

.synot-content .live-match {
  background-color: rgba(210, 30, 30, .1);
}

.synot-content .link {
  padding-bottom: 15px;
}

.score-middle .list-score.list-score-middle .team-container.team-away .team-name {
  text-align: left;
  padding: 0 82px 0 5px;
}

.list-score-tennis {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  min-height: 70px;
  padding: 6px 0 0;
}

.list-score-tennis .players-container {
  float: left;
  width: 100%;
}

.list-score-tennis .players-container .player-container {
  width: 100%;
  height: auto;
  min-height: 25px;
  position: relative;
}

.list-score-tennis .players-container .player-container .name-container {
  float: left;
  width: 50%;
}

.list-score-tennis .players-container .player-container .name-container .logo-container {
  text-align: center;
  float: left;
  width: 20%;
  max-width: 30px;
}

.list-score-tennis .players-container .player-container .name-container .logo-container img {
  width: auto;
  height: 25px;
  position: relative;
  top: 2px;
}

.list-score-tennis .players-container .player-container .name-container .player-name {
  float: left;
  max-width: 70%;
  margin: 8px 0 0;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .players-container .player-container .name-container .player-name.no-logo {
  margin-left: 20%;
}

.list-score-tennis .players-container .player-container .name-container .player-name.player-looser {
  color: #828282;
}

.list-score-tennis .players-container .player-container .name-container .score-container {
  float: right;
  padding: 8px 0 0 5px;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .players-container .player-container .name-container .service-container {
  float: right;
}

.list-score-tennis .players-container .player-container .name-container .service-container .dataimg-tennis-ball {
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  position: relative;
  top: 8px;
}

.list-score-tennis .players-container .player-container .result-container {
  float: left;
  width: 50%;
}

.list-score-tennis .players-container .player-container .result-container .set-container {
  float: left;
  padding-left: 25px;
}

.list-score-tennis .players-container .player-container .result-container .set-container .set-value {
  float: left;
  text-align: left;
  width: 25px;
  padding: 8px 0;
  font-size: 13px;
  font-weight: 400;
}

.list-score-tennis .players-container .player-container .result-container .set-container .gem {
  float: left;
  padding: 5px 0;
  display: block;
}

.list-score-tennis .players-container .player-container .bet-button {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.list-score-tennis .players-container .player-container:after, .list-score-tennis .players-container .player-container:before, .list-score-tennis .players-container:after, .list-score-tennis .players-container:before {
  content: "";
  clear: both;
  display: table;
}

.list-score-tennis .match-info {
  color: #828282;
  float: left;
  width: 37%;
  padding: 14px 0 0 30px;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .league-section {
  position: relative;
}

.list-score-tennis.status-sched .players-container {
  width: 50%;
}

.list-score-tennis.status-sched .players-container .name-container {
  width: 100%;
}

.list-score-tennis.status-sched .players-container .result-container {
  width: 5%;
}

.list-score-tennis.status-live {
  padding: 4px 0 0;
}

.list-score-tennis.status-live .name-container .score-container {
  color: #d21e1e;
}

.list-score-tennis.status-live .set-container .set-value:last-of-type {
  color: #ff6000;
}

.list-score-tennis.dark {
  background-color: #1e1e1e;
  border-color: #646464;
}

.list-score-tennis.dark .players-container .player-container .name-container, .list-score-tennis.dark .players-container .set-container .set-value {
  color: #fff;
}

.list-score-tennis.dark.status-live .set-container .set-value:last-of-type {
  color: #ff6000;
}

.list-score-tennis:first-of-type .list-score {
  border-top: 1px solid #dcdcdc;
}

.list-score-tennis:after, .list-score-tennis:before {
  content: "";
  clear: both;
  display: table;
}

.list-score-no-link:first-of-type .list-score-tennis {
  border-top: 1px solid #dcdcdc;
}

a.list-score-link .list-score-tennis:hover {
  background-color: #fdf2f2;
  transition: all .5s;
}

a.list-score-link .list-score-tennis:hover .players-container .player-container .name-container .score-container .score {
  color: #d21e1e;
}

a.list-score-link .list-score-tennis.dark:hover .players-container .player-container .name-container .score-container .score {
  color: #791111;
  transition: all .2s;
}

a.list-score-link:first-of-type .list-score-tennis {
  border-top: 1px solid #dcdcdc;
}

.match-bet-cover-tennis {
  float: right;
  width: 222px;
  height: 68px;
  margin: -68px auto 4px;
  padding-bottom: 15px;
}

.match-bet-cover-tennis a {
  z-index: 10;
  display: block;
}

.match-bet-cover-tennis a:hover {
  text-decoration: none;
}

.match-bet-cover-tennis a img.bets {
  width: 50px;
  height: auto;
  margin: 2px auto;
  display: inline-block;
}

.match-bet-cover-tennis .tipsport-tv-url .match-online.tenis {
  width: 100%;
  margin-top: 4px;
  font-size: 13px;
  font-weight: normal;
}

.match-bet-cover-tennis .tipsport-tv-url .match-online.tenis .match-online-text .tipsport {
  float: right;
  color: #0197f4;
}

.four-col.right-col .match-bet-cover-tennis {
  display: none;
}

@media screen and (max-width: 760px) {
  .list-score-tennis {
    border-bottom: 0;
    min-height: 60px;
    padding: 15px 0 0;
  }

  .match-bet-cover-tennis {
    z-index: 100;
    margin-right: 15px;
    position: relative;
  }

  .match-bet-cover-tennis .match-online div.gray {
    margin-right: 6px;
  }
}

@media screen and (max-width: 680px) {
  .match-bet-cover-tennis {
    width: 111px;
  }

  .match-bet-cover-tennis .match-online div.gray {
    margin-right: 0;
  }
}

@media screen and (max-width: 450px) {
  .match-bet-cover-tennis {
    float: right;
    width: 68px;
    margin-top: -54px;
    margin-right: 2px;
  }
}

@media screen and (max-width: 360px) {
  .tipsport-tv-url {
    max-height: 54px;
  }

  .match-bet-cover-tennis {
    width: 46px;
  }
}

.list-score-other {
  min-height: 30px;
  font-size: 13px;
}

.list-score-other:nth-of-type(2n) {
  background-color: #f0f0f0;
}

.list-score-other:hover .datetime-container {
  color: #d21e1e;
  transition: all .2s;
}

.list-score-other .datetime-container {
  color: #828282;
  float: left;
  padding: 6px 10px;
  display: inline-block;
}

.list-score-other .event-container {
  float: right;
  width: 80%;
  padding: 6px 10px;
  font-weight: 700;
}

.list-score-other .event-container:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.list-score-other .event-container .event-description {
  float: left;
  width: 85%;
}

.list-score-other .event-container .live {
  float: right;
  text-transform: uppercase;
  color: #d21e1e;
  width: auto;
  font-weight: 700;
}

.list-score-other.status-live .datetime-container {
  color: #d21e1e;
}

.list-score-other.dark .event-container {
  color: #fff;
}

.list-score-other.dark:hover .datetime-container {
  color: #791111;
  transition: all .2s;
}

@media screen and (max-width: 760px) {
  .list-score-other.date-show .event-container {
    float: none;
    width: 100%;
  }
}

.list-score-oh {
  min-height: 30px;
  font-size: 13px;
}

.list-score-oh:nth-of-type(2n) {
  background-color: #f0f0f0;
}

.list-score-oh:hover .datetime-container {
  color: #d21e1e;
  transition: all .2s;
}

.list-score-oh .datetime-container {
  color: #828282;
  float: left;
  padding: 6px 10px;
  display: inline-block;
}

.list-score-oh .event-container {
  float: right;
  width: 80%;
  padding: 6px 10px;
  font-weight: 700;
}

.list-score-oh .event-container:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.list-score-oh .event-container .event-description {
  float: left;
  width: 85%;
}

.list-score-oh .event-container .live {
  float: right;
  text-transform: uppercase;
  color: #d21e1e;
  width: auto;
  font-weight: 700;
}

.status-live.list-score-oh .datetime-container {
  color: #d21e1e;
}

.dark.list-score-oh .event-container {
  color: #fff;
}

.dark.list-score-oh:hover .datetime-container {
  color: #791111;
  transition: all .2s;
}

.list-score-oh {
  min-height: 50px;
}

.list-score-oh.status-live .datetime-container span, .list-score-oh.status-live .event-container .event-description {
  color: #d21e1e;
}

.list-score-oh.status-live .event-container .medal-wrapp .icon-medail-green {
  background: url("/images/isportcz/dist/non-sprite/medail-red.png");
}

.list-score-oh.status-live .event-container .medal-wrapp .medal-text {
  color: #d21e1e;
}

.list-score-oh .datetime-container {
  width: 14%;
  padding: 0;
}

.list-score-oh .datetime-container span {
  color: #1e1e1e;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-score-oh .event-container {
  float: left;
  width: 86%;
  margin-left: 14%;
  padding: 9px 6px;
}

.list-score-oh .event-container .event-description {
  width: 65%;
}

.list-score-oh .event-container .medal-wrapp {
  margin-top: 3px;
  margin-left: 3%;
}

.list-score-oh .event-container .live {
  margin-top: 7px;
}

@media screen and (max-width: 760px) {
  .list-score.list-score-oh .datetime-container {
    border-right: 1px solid #dcdcdc;
    width: 14%;
    padding: 0;
    font-size: 13px;
    font-weight: 700;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .list-score.list-score-oh .datetime-container span {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .list-score.list-score-oh .event-container .event-description {
    width: 58%;
  }

  .list-score.list-score-oh .event-container .medal-wrapp {
    margin-left: 1%;
  }

  .list-score.list-score-oh .event-container .medal-wrapp .medal-text {
    float: left;
    margin-top: -1px;
    margin-left: 20px;
    font-size: 9px;
  }
}

.link-basic {
  text-align: center;
}

a.link {
  color: #d21e1e;
  align-items: center;
  width: auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: underline;
  display: inline-flex;
}

a.link:hover {
  text-decoration: none;
  transition: color .2s;
}

a.link .icon {
  width: 5px;
  height: 9px;
  margin: 0 0 0 4px;
  display: flex;
  position: relative;
}

a.link.variant {
  text-transform: uppercase;
  font-weight: 700;
}

a.link.variant .icon {
  margin-top: 5px;
}

a.link--button-default {
  color: #fff;
  background: #d21e1e;
  border-radius: 2px;
  padding: 10px 37px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

a.link.football-euro {
  color: #02849a;
}

a.link:after, a.link:before {
  content: "";
  clear: both;
  display: table;
}

.link--small, .link-big {
  text-align: left;
  border-bottom: 2px solid #dcdcdc;
  padding: 12px 0;
  font-size: 22px;
  font-weight: 700;
  display: block;
}

.link--small:hover, .link-big:hover {
  text-decoration: underline;
  transition: color .4s;
}

.link--small:last-of-type, .link-big:last-of-type {
  border-bottom: 0;
  margin-bottom: 15px;
}

.no-margin.link--small, .no-margin.link-big {
  margin: 0;
}

.link--small .icon, .link-big .icon {
  float: right;
  width: 5px;
  height: 9px;
  margin-right: 10px;
  padding-top: 10px;
}

.active.link--small, .active.link-big {
  color: #d21e1e;
}

.active.left-overlap.link--small, .active.left-overlap.link-big {
  color: #1e1e1e;
  background: #fff;
  border-color: #fff;
  border-top-style: solid;
  border-top-width: 2px;
  width: 360px;
  padding-left: 30px;
  position: relative;
  top: -2px;
  left: -30px;
}

.link--small:first-of-type.active, .link-big:first-of-type.active {
  border-top: 0;
  top: 0;
}

.link-medium.link--small, .link-medium.link-big {
  border-bottom: 1px solid #dcdcdc;
  padding: 7px 0;
  font-size: 13px;
}

.link-medium.link--small .icon, .link-medium.link-big .icon {
  margin-right: 0;
  padding-top: 0;
}

.link-medium.link--small:first-of-type, .link-medium.link-big:first-of-type {
  border-top: 1px solid #dcdcdc;
}

.link-big:after, .link-big:before {
  content: "";
  clear: both;
  display: table;
}

.link--small {
  border-bottom: none;
  margin-bottom: 0;
  padding: 7px 0;
  font-size: 13px;
}

.link--small:last-of-type {
  margin-bottom: 0;
}

.link--small .icon {
  padding-top: 4px;
}

.link--small:after, .link--small:before {
  content: "";
  clear: both;
  display: table;
}

.list-title {
  color: #1e1e1e;
  width: 100%;
  margin-bottom: 15px;
}

.list-title:last-of-type {
  margin-bottom: 0;
}

.list-title .isport-premium-list-wrapper {
  width: 73px;
  height: 20px;
  margin: 0 7px 0 0;
  display: inline-block;
  position: relative;
  top: 1px;
}

.list-title .title-meta {
  padding-left: 30px;
}

.list-title .title-meta .isport-premium {
  top: 5px;
}

.list-title .flag-container {
  float: left;
  text-align: left;
  width: 30px;
  height: 20px;
  margin: 2px 0;
}

.list-title .flag-container .flag {
  display: inline-block;
  position: relative;
}

.list-title .flag-container .dataimg-play {
  width: 15px;
  height: 15px;
  top: 2px;
}

.list-title .flag-container .dataimg-indent {
  width: 12px;
  height: 12px;
  top: 5px;
}

.list-title .title-container {
  float: right;
  width: calc(100% - 30px);
}

.list-title .title-container h3 {
  margin: 2px 0 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.list-title.dark h3 a {
  color: #fff;
}

.list-title:after, .list-title:before {
  content: "";
  clear: both;
  display: table;
}

.list-title-indent {
  margin-bottom: 20px;
  padding-left: 30px;
}

.superlife .smart-underline:hover {
  color: #02a555;
  box-shadow: none;
}

.superlife .list-title-indent {
  padding-left: 0;
}

.superlife .list-title-indent .title-basic {
  color: #02a555;
  text-transform: none;
  text-decoration: none;
}

.superlife .link.sport-color {
  color: #02a555;
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  article.list-title {
    margin-bottom: 12px;
  }

  article.list-title .title-meta {
    padding-left: 20px;
  }

  article.list-title > .flag-container {
    width: 20px;
  }

  article.list-title > .flag-container .flag {
    width: 8px;
    height: 8px;
    top: 3px;
  }

  article.list-title > .title-container {
    width: calc(100% - 20px);
  }

  article.list-title > .title-container h3 {
    font-size: .8em;
  }

  .list-title-indent {
    padding-left: 20px;
  }
}

.title-basic {
  text-transform: uppercase;
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}

.title-basic--superlife {
  color: #02a555;
  text-transform: none;
  font-size: 35px;
}

a .title-basic {
  text-decoration: none;
}

a .title-basic:hover {
  text-decoration: underline;
  transition: color .2s;
}

@media screen and (max-width: 760px) {
  .title-basic {
    font-size: .7em;
  }
}

.eventNejnovejsi .title-basic {
  font-size: 22px;
}

.title-section {
  justify-content: space-between;
  width: 100%;
  margin: 0;
  display: flex;
  position: relative;
}

.title-section.border-top {
  border-top-style: solid;
  border-top-width: 2px;
  padding-top: 5px;
}

.title-section.border-no {
  border-bottom-width: 0;
}

.title-section.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: auto;
  margin: 7px 0 5px;
  padding-bottom: 2px;
}

.title-section.in-box {
  padding-left: 15px;
  padding-right: 15px;
}

.title-section.color-inverze {
  color: #fff;
}

.title-section .main-title {
  float: left;
  margin: 5px 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.title-section .main-title.search-title {
  float: none;
  display: inline-block;
}

.title-section .main-title--flex {
  flex-basis: 100%;
  justify-content: space-between;
  display: flex;
}

.title-section .sub-title {
  float: right;
  color: #aaa;
  margin-top: 13px;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
}

.title-section .sub-link {
  margin-top: 9px;
  display: block;
}

.title-section .sub-link span {
  float: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.title-section .sub-link .logout-user {
  text-transform: lowercase;
  font-weight: 400;
}

.title-section .sub-link:hover, .title-section .sub-link:hover span {
  text-decoration: underline;
}

.title-section .logged-user {
  float: right;
  font-size: 13px;
}

.title-section .list-links {
  float: right;
  margin: 12px 0 0;
  font-family: Roboto, arial, sans-serif;
}

.title-section.sport-color-bg .main-title {
  color: #fff;
  height: 40px;
  margin: 10px 0;
  padding: 7px 10px;
}

.title-section.sport-color-bg .main-title a {
  color: #fff;
}

.title-section.box-title .main-title {
  text-transform: uppercase;
  float: none;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
}

.title-section .icon {
  float: right;
  width: 5px;
  height: 9px;
  margin-top: -5px;
  margin-left: 3px;
  padding-top: 3px;
}

.title-section .icon-section {
  background-image: none;
  width: 25px;
  height: 25px;
  margin-top: -8px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.title-section .dropdown-menu {
  float: right;
}

.title-section .dropdown-menu > ul {
  margin: 5px 0 0;
}

.title-section .dropdown-menu > ul li.dropdown-title {
  height: auto;
}

.title-section .dropdown-menu > ul li.dropdown-title:hover {
  background: #fff;
}

.title-section .dropdown-menu > ul li.dropdown-title > a {
  padding: 10px 30px 10px 10px;
}

.title-section .dropdown-menu > ul li.dropdown-title > a:after {
  border: 0;
}

.title-section .dropdown-menu > ul li.dropdown-title .dataimg-arrow-down {
  top: 14px;
}

.title-section .dropdown-menu > ul li.dropdown-title ul {
  right: 0;
}

.title-section .dropdown-menu.half-items .dropdown-title ul li {
  float: left;
  width: 50%;
}

.title-all-seasons .title-section .dropdown-menu > ul li.dropdown-title > a {
  color: #1e1e1e;
}

.title-all-seasons:after, .title-all-seasons:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 760px) {
  .title-section .main-title {
    margin-top: 13px;
    font-size: 1em;
  }

  .title-section .icon-section {
    width: 20px;
    height: 20px;
    top: 3px;
  }

  .title-section.box-title .main-title {
    font-size: .8em;
  }

  .title-section .list-links {
    float: none;
    clear: both;
    font-family: Roboto, arial, sans-serif;
  }

  .title-section .list-links li {
    border-bottom: 1px solid #bebebe;
    margin: 0;
    display: block;
  }

  .title-section .list-links li:first-of-type {
    border-top: 1px solid #bebebe;
  }

  .title-section .list-links li a {
    padding: 8px;
    display: block;
  }
}

.results__league-title-link {
  align-items: center;
  display: flex;
}

.results__league-title-link .icon-section {
  width: 20px;
  height: 20px;
  margin: 0 7px 0 0;
  display: block;
  position: relative;
  top: 50%;
}

.results__league-title-icon svg {
  width: 18px;
  height: 18px;
}

.header-league {
  color: #fff;
}

.header-league .header-wrapper {
  font-size: 13px;
  position: relative;
}

.header-league .header-wrapper a {
  color: #fff;
}

.header-league .header-wrapper .title-wrapper {
  border-right: 1px solid #fff;
  width: 68%;
  min-height: 70px;
  max-height: 75px;
  margin: 10px 0;
}

.header-league .header-wrapper .title-wrapper .logo {
  text-align: center;
  width: 36%;
  margin-right: 5px;
}

.header-league .header-wrapper .title-wrapper .logo.wide {
  width: 43%;
}

.header-league .header-wrapper .title-wrapper .title {
  width: 61%;
}

.header-league .header-wrapper .title-wrapper .title h3 {
  margin: 15px 0 6px;
  font-family: Roboto, arial, sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper .title h3.h3-header-league-fortuna {
    margin-top: 8px;
    font-size: 20px;
  }
}

.header-league .header-wrapper .title-wrapper .title.narrow {
  width: 53%;
}

.header-league .header-wrapper .title-wrapper .subtitle.with-rounds {
  cursor: pointer;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener {
  width: 100px;
  margin-left: -22px;
  padding: 10px 10px 17px 80px;
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 90px;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener .rounds-visible {
  opacity: 1;
  visibility: visible;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener .rotate-180 {
  transform: rotate(180deg);
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
  margin-left: -6px;
  padding: 0 11px 17px 79px;
  top: 48px;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna .rounds-col {
  width: 50%;
}

@media (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
    top: 40px;
    left: 86px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
    top: 40px;
    left: 73px;
  }
}

.header-league .header-wrapper .title-wrapper:after, .header-league .header-wrapper .title-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.header-league .header-wrapper .title-wrapper-fortuna {
  margin: 0;
}

.header-league .header-wrapper .title-wrapper-fortuna .title h3.h3-header-league-fortuna {
  margin-top: 4px;
}

@media screen and (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna {
    min-height: 0;
    max-height: 66px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 375px) {
  .header-league .header-wrapper .title-wrapper-fortuna {
    max-height: 57px;
    margin-top: 5px;
  }
}

@media (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna {
    width: 78px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna {
    width: 59px;
  }
}

.header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .dataimg-logo-fortuna-liga svg {
  max-width: 78px;
  max-height: 78px;
}

@media (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 76px;
    height: 76px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

@media (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 69px;
    height: 69px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 69px;
    height: 69px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

.header-league .header-wrapper .rounds {
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  width: 330px;
  padding-top: 0;
  transition: all .3s;
  position: absolute;
  top: 33px;
  left: -95px;
}

.header-league .header-wrapper .rounds .rounds-col {
  float: left;
  width: 165px;
  margin-top: 20px;
}

.header-league .header-wrapper .rounds a {
  color: #1e1e1e;
}

.header-league .header-wrapper .rounds .main-title a {
  color: #00a442;
}

@media (max-width: 426px) {
  .header-league .header-wrapper .rounds {
    width: 300px;
    margin-left: 20px;
    top: 35px;
  }
}

.header-league .header-wrapper .link-wrapper {
  text-transform: uppercase;
  width: 31%;
  padding: 0 0 0 7px;
  font-weight: 700;
}

.header-league .header-wrapper .link-wrapper .link {
  margin: 4px 0 5px !important;
}

.header-league .header-wrapper .link-wrapper .link a {
  color: #fff;
}

.header-league .header-wrapper .link-wrapper .link .arrow {
  float: right;
  width: 5px;
  max-height: 12px;
  margin: 3px 15px 0 0;
  display: inline-block;
}

.header-league .header-wrapper .link-wrapper .link:after, .header-league .header-wrapper .link-wrapper .link:before {
  content: "";
  clear: both;
  display: table;
}

.header-league .header-wrapper .link-wrapper .sidebar-text {
  line-height: 1.5em;
}

.header-league .header-wrapper .link-wrapper .sidebar-text-fortuna {
  margin-top: 0;
  font-size: 13px;
}

@media screen and (max-width: 376px) {
  .header-league .header-wrapper .link-wrapper .sidebar-text-fortuna {
    font-size: 11px;
  }
}

.header-league .header-wrapper .link-wrapper:after, .header-league .header-wrapper .link-wrapper:before, .header-league .header-wrapper:after, .header-league .header-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 760px) {
  .header-league .header-wrapper .title-wrapper .logo {
    width: 34%;
  }

  .header-league .header-wrapper .title-wrapper .title {
    width: 63%;
  }

  .header-league .header-wrapper .link-wrapper {
    width: 32%;
  }
}

.title-small .header-league .title-wrapper .title h3 {
  font-size: 20px;
}

.list-video-small {
  color: #1e1e1e;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.list-video-small a {
  float: left;
  display: block;
  position: relative;
}

.list-video-small a .flag {
  z-index: 2;
  width: 40px;
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-video-small a .flag .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

.list-video-small .box-title {
  float: left;
  width: 46%;
  margin-left: 10px;
}

.list-video-small .box-title .title-container {
  float: left;
  min-height: 70px;
}

.list-video-small .box-title .title-container .title {
  margin-bottom: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.list-video-small .box-title .title-container .title .title-link {
  float: initial;
}

.list-video-small .box-title .additional-value-container {
  clear: both;
  height: 15px;
  display: inline-block;
}

.list-video-small:hover .flag .circle {
  opacity: .8;
}

.list-video-small:after, .list-video-small:before {
  content: "";
  clear: both;
  display: table;
}

article.list-article.list-article-normal .image-container {
  float: left;
  width: 47.83%;
  max-height: 180px;
  position: relative;
  overflow: hidden;
}

article.list-article.list-article-normal .image-container .flag {
  width: 50px;
  height: 50px;
}

article.list-article.list-article-normal .title-container {
  float: right;
  width: 47.83%;
  margin: 10px 1.5% 0 0;
}

article.list-article.list-article-normal .title-container h2 {
  font-size: 26px;
  line-height: 30px;
}

article.list-article.list-article-normal .title-container.no-image {
  float: none;
  width: 100%;
  margin: 0;
  padding: 10px;
}

article.list-article.list-article-normal .meta {
  margin-bottom: 2px;
  display: flex;
}

@media screen and (max-width: 760px) {
  article.list-article.list-article-normal {
    padding-bottom: 8px;
    position: relative;
    overflow: hidden;
  }

  article.list-article.list-article-normal > .image-container {
    width: 40%;
    margin-top: 10px;
  }

  article.list-article.list-article-normal > .title-container {
    width: 55%;
  }

  article.list-article.list-article-normal > .title-container h2 {
    font-size: 1.3em;
    line-height: 1.3;
  }
}

@media screen and (max-width: 450px) {
  article.list-article.list-article-normal > .image-container .flag {
    width: 40px;
    height: 40px;
  }

  article.list-article.list-article-normal > .title-container h2 {
    font-size: 1em;
  }
}

.carousel-matches {
  float: none;
  margin: 15px auto;
  display: block;
}

.carousel-matches .list-video-small a {
  float: none;
}

.carousel-matches .list-video-small .box-title {
  float: none;
  width: 145px;
  margin: 10px auto;
}

.carousel-matches .list-video-small .box-title .title-container {
  float: none;
  margin-top: 10px;
}

.carousel-matches .list-video-small .box-title .title-container a {
  display: inline-block;
}

.carousel-matches .video-art.more-art {
  vertical-align: text-top;
  width: 27%;
  display: inline-block;
}

.carousel-matches .owl-carousel .owl-nav .owl-prev, .carousel-matches .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 35px;
  left: -50px;
}

.carousel-matches .owl-carousel .owl-nav .owl-prev div, .carousel-matches .owl-carousel .owl-nav .owl-next div {
  cursor: pointer;
  width: 15px;
  height: 25px;
}

.carousel-matches .owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.carousel-matches .owl-carousel .owl-dots {
  counter-reset: dots;
  text-align: center;
  margin-bottom: 15px;
}

.carousel-matches .owl-carousel .owl-dots .owl-dot:before {
  counter-increment: dots;
  content: counter(dots);
}

.carousel-matches .owl-carousel .owl-dots .owl-dot {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.carousel-matches .owl-carousel .owl-dots .owl-dot.active:before {
  color: #00a442;
  font-weight: 700;
}

.league-promo .live-match {
  background-color: rgba(210, 30, 30, .1);
  padding: 6px 15px 0;
  position: relative;
}

.league-promo .live-match:first-of-type .white-line {
  z-index: 1000;
  background: #fff;
  width: 100%;
  height: 2px;
  position: absolute;
  top: -2px;
  left: 0;
}

.league-promo .live-match:last-of-type hr {
  display: none;
}

.league-promo .live-match hr {
  border-color: rgba(210, 30, 30, .3);
}

.league-promo .live-match a.list-score-link:hover .list-score {
  border-bottom: 0;
}

.league-promo .live-match a.list-score-link:hover .list-score .score-container .score, .league-promo .live-match a.list-score-link:hover .list-score .score-container .flag {
  color: #791111;
  transition: color .5s;
}

.league-promo .live-match .flag.flag-live {
  color: #d21e1e;
  top: -46px;
}

.league-promo .live-match .flag.flag-live:before {
  content: " ●";
  color: #d21e1e;
  margin-top: 3px;
  margin-right: 3px;
  font-size: 15px;
}

.league-promo .link {
  padding-bottom: 15px;
}

.league-promo hr {
  margin: 0;
}

.league-promo span.box-match {
  display: block;
}

.league-promo span.box-match:last-of-type hr, .league-promo div.box-match:nth-last-of-type(3) hr {
  display: none;
}

.league-promo .video-small {
  padding-bottom: 15px;
}

body .other-leagues .list-score.list-score-middle .team-container .team-name {
  width: 56%;
}

body .other-leagues .league-promo {
  margin: 20px 10px;
  font-family: roboto, arial, sans-serif;
}

body .other-leagues .league-promo .title-section .dropdown-menu > ul li.dropdown-title .dataimg-arrow-down {
  background: url("/images/blesk/arrow-down-blesk.png") center no-repeat;
}

body .other-leagues .league-promo .list-video-small > a {
  width: 50%;
}

body .other-leagues .league-promo .list-video-small > a > img {
  width: 100%;
}

body .other-leagues .league-promo .header-league .header-wrapper .link-wrapper {
  padding: 10px 0 0;
}

body .other-leagues .league-promo .header-league .header-wrapper .link-wrapper .link {
  padding: 0;
}

body .other-leagues .league-promo .header-league .header-wrapper .link-wrapper .link a {
  padding-left: 5px;
}

body .other-leagues .league-promo .header-league .header-wrapper .link-wrapper .link .arrow {
  margin: 3px 5px 0 0;
}

body .other-leagues .league-promo .header-league .header-wrapper .link-wrapper .link .arrow .dataimg-arrow-right {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%205%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M5%2C4.3L1.3%2C0L0%2C0.7l3.1%2C3.7L0%2C8.2L1.3%2C9L5%2C4.6V4.3z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  width: 100%;
  height: 12px;
}

body .other-leagues .league-promo .list-score.list-score-small .team-container.team-home .team-name, body .other-leagues .league-promo .list-score.list-score-small .team-container.team-away .team-name {
  width: 65%;
}

.other-leagues.hockey .link.sport-color {
  padding-bottom: 0;
}

.other-leagues.hockey .link.sport-color .dataimg-arrow-right {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%205%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%3E%3Cpath%20fill%3D%22%23325EDC%22%20d%3D%22M5%2C4.3L1.3%2C0L0%2C0.7l3.1%2C3.7L0%2C8.2L1.3%2C9L5%2C4.6V4.3z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  width: 5px;
  height: 12px;
}

.other-leagues.hockey.mt-20.mb-20 {
  width: 100%;
  margin: 0;
}

ul.links-separated li a, a.list-score-link .list-score .score-container {
  font-family: roboto, arial, sans-serif;
}

.icon-logo-ms-2017 {
  background-image: url("/images/isportcz/dist/non-svg/logo-ms-2017.png");
  width: 51px;
  height: 75px;
}

@media (max-width: 760px) {
  .other-leagues.hockey .header-league .header-wrapper .link-wrapper {
    width: 30%;
  }

  .other-leagues.hockey .header-league .header-wrapper a {
    font-size: 12px;
  }
}

.sportmod-team-link {
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 20px auto;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 20px;
  display: flex;
}

.sportmod-team-link:hover {
  color: #fff;
}

.sportmod-team-link__logo {
  width: 40px;
  height: 40px;
}

.sportmod-team-link__logo.no-resize {
  width: initial;
  height: initial;
}

.sportmod-team-link--football {
  background: #00a442;
}

.sportmod-team-link--football:hover {
  background: #00b348;
}

.sportmod-team-link--hockey {
  background: #325edc;
}

.sportmod-team-link--hockey:hover {
  background: #3f68de;
}

.ad-pr-sticker {
  color: #000;
  text-transform: uppercase;
  background: #d8d8db;
  margin-right: 5px;
  padding: 5px;
  font-size: 11px;
  font-weight: 700;
  line-height: 9px;
  display: inline-block;
}

.premium-sticker {
  background-image: url("http://img2.cncenter.cz/images/default/svg/premium-sticker-dark.svg");
  background-position: 0;
  background-repeat: no-repeat;
  width: 88px;
  height: 20px;
  padding: 10px 12px;
  font-size: 0;
  line-height: 22px;
  text-decoration: none;
  display: flex;
}

.premium-sticker:hover, .premium-sticker:focus {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 760px) {
  .premium-sticker {
    font-size: 0;
    line-height: initial;
    background-size: 70px;
    width: 70px;
  }
}

.premium-sticker--inline {
  vertical-align: text-bottom;
  margin-right: 10px;
  display: inline-block;
}

.premium-sticker--small {
  padding-top: 2px;
  font-size: 0;
  line-height: 12px;
}

.premium-sticker--small-mobile {
  line-height: 21px;
}

.premium-sticker--videoplayer-big {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
}

@media (max-width: 760px) {
  .related-articles-list .premium-sticker {
    position: absolute;
    top: 0;
    left: 20px;
  }
}

.category-flags--big .premium-sticker {
  background-image: url("http://img2.cncenter.cz/images/default/svg/premium-sticker-dark.svg");
  width: 88px;
  height: 22px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.category-flags--big-mobile {
  height: 24px;
  padding-left: 3px;
}

@media (max-width: 760px) {
  .list-article-vertical-half .premium-sticker {
    background-size: 63px;
  }
}

.header-promo-league, .header-promo-league a, .header-promo-league a:hover {
  color: #fff;
}

.header-promo-league__container {
  align-items: stretch;
  display: flex;
}

.header-promo-league__logo {
  flex-basis: 22%;
  line-height: 0;
}

.header-promo-league__logo--fortuna, .header-promo-league__logo--liga-mistru {
  max-width: 70px;
}

.header-promo-league__logo--liga-mistru {
  padding: 4px;
}

.header-promo-league__logo--liga-mistru svg path:first-child {
  fill: #00a442;
}

.header-promo-league__logo--liga-mistru svg path {
  fill: #fff;
}

.header-promo-league__logo--tipsport-extraliga {
  flex-basis: auto;
  padding: 10px;
}

.header-promo-league__title {
  flex-direction: column;
  flex-basis: 58%;
  align-self: center;
  margin: 5px 5px 5px 7px;
  display: flex;
}

.header-promo-league__title h3 {
  text-align: center;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
}

.header-promo-league__title h3 span {
  color: #fff;
  text-transform: initial;
  font-size: 12px;
  font-weight: 300;
  display: block;
}

.header-promo-league__title h3.dropdown-title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 300;
}

.header-promo-league__title--fortuna, .header-promo-league__title--liga-mistru {
  flex-basis: calc(59% - 70px);
}

.header-promo-league__title--tipsport-extraliga {
  margin-left: 0;
}

.header-promo-league__title--tipsport-extraliga h3 {
  text-align: left;
}

.header-promo-league__title.header-promo-league__title--no-logo {
  margin-left: 10px;
}

.header-promo-league__title.header-promo-league__title--no-logo h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.header-promo-league__links {
  text-transform: uppercase;
  border-left: 1px solid #fff;
  flex-basis: 42%;
  align-items: center;
  margin: 5px 5px 5px 0;
  padding-left: 10px;
  font-weight: 700;
  display: flex;
}

.header-promo-league__links ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-promo-league__links ul li {
  width: 100%;
  margin: 3px 0;
  font-family: roboto, arial, sans-serif;
  font-size: 11px;
  line-height: 13px;
  position: relative;
}

.header-promo-league__links ul a {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.header-promo-league__links svg {
  height: 10px;
}

.header-promo-league__links svg path {
  fill: #fff;
}

.header-promo-league__links--no-logo {
  margin-right: 10px;
}

.header-promo-league__links--fortuna {
  flex-basis: 41%;
}

.header-promo-league__links--liga-mistru, .header-promo-league__links--tipsport-extraliga {
  flex-basis: 38%;
}

.header-promo-league__link-name {
  width: calc(100% - 5px);
}

.header-promo-league__link-icon {
  width: 5px;
}

.header-promo-league__links-text {
  font-family: roboto, arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.3;
}

.header-promo-league__submenu-title {
  text-transform: none;
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  font-family: roboto, arial, sans-serif;
  font-size: 12px;
  display: flex;
  position: relative;
}

.header-promo-league__submenu-title .dataimg-arrow-down {
  width: 10px;
  height: 6px;
  margin: -8px 0 0 5px;
}

.header-promo-league__submenu-title .dataimg-arrow-down svg {
  height: 6px;
}

.header-promo-league__submenu-title .dataimg-arrow-down.rotate-180 {
  transform: rotate(180deg);
}

.header-promo-league__submenu-content {
  z-index: 300;
  background: #fff;
  width: 300px;
  padding-top: 0;
  display: none;
  position: absolute;
}

.header-promo-league__submenu-content.visible {
  display: block;
}

.header-promo-league__submenu-content a.link-big {
  color: #1e1e1e;
}

.header-promo-league__submenu-content-half {
  float: left;
  width: 50%;
  margin-top: 20px;
}

.header-promo-league.football {
  background-color: #00a442;
}

.header-promo-league.hockey {
  background-color: #325edc;
}

.header-promo-league.hockey .header-promo-league__title h3 span {
  color: #fff;
}

.header-promo-league--loh-tokio {
  background: #771a2a url("//img2.cncenter.cz//images/isportcz/standalone/loh/loh-tokio-bg-header-promo.png") 100% 0 no-repeat;
}

.header-promo-league--loh-tokio .header-promo-league__title {
  flex-basis: 50%;
}

.header-promo-league--loh-tokio .header-promo-league__title.header-promo-league__title--no-logo h3 {
  font-size: 17px;
  line-height: 18px;
}

.header-promo-league--loh-tokio .header-promo-league__links {
  border-left: 1px solid #b11d33;
  flex-basis: 50%;
}

.header-promo-league--loh-tokio .header-promo-league__links ul li {
  font-size: 12px;
  line-height: 15px;
}

.header-promo-league--football-euro.football {
  background: #015d6b url("//img2.cncenter.cz//images/isportcz/standalone/euro-2021/bg-header-promo-special.png") 100% 0 no-repeat;
}

.header-promo-league--football-euro.football .header-promo-league__title.header-promo-league__title--no-logo h3 {
  font-size: 17px;
  line-height: 18px;
}

.header-promo-league--football-euro.football .header-promo-league__links {
  border-left: 1px solid #015d6b;
}

.header-promo-league__matches {
  background: #fff;
  padding: 0 0 15px;
}

.header-promo-league__matches .box-container {
  margin: 0;
}

.header-promo-league__matches .list-score.list-score-middle {
  border-bottom: 1px solid #dcdcdc;
}

.header-promo-league__matches .list-score.list-score-middle .team-container, .header-promo-league__matches .list-score.list-score-middle.team-name {
  font-size: 13px;
}

.header-promo-league__matches .list-score.list-score-middle .score-container .flag {
  top: -3px;
}

.header-promo-league__matches .list-score.list-score-small .flag-live {
  font-size: 10px;
}

.header-promo-league__matches .list-score .team-container .logo-container img {
  max-width: 30px;
  margin-top: 3px;
}

.header-promo-league__matches .link.sport-color {
  z-index: 10;
  text-align: center;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00ffffff", endColorstr= "#fff", GradientType= 0);
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 17% 18%);
  width: 100%;
  height: 50px;
  margin: 0;
  line-height: 50px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.match-preview {
  color: #1e1e1e;
  flex-flow: column;
  align-items: center;
  padding: 5px;
  display: flex;
}

.match-preview:nth-child(2n) {
  background-color: #fff;
}

.match-preview__link {
  color: #1e1e1e;
  flex-direction: column;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview__link:hover .match-preview__team-name--looser {
  color: #d21e1e;
}

.match-preview__row {
  width: 100%;
  display: flex;
}

.match-preview__team {
  flex-basis: 38.5%;
  align-items: center;
  display: flex;
}

.match-preview__team--home {
  justify-content: flex-end;
}

.match-preview__team--away {
  justify-content: flex-start;
}

.match-preview__team-name {
  font-size: 16px;
  font-weight: 700;
}

.match-preview__team-name--home {
  padding-right: 5px;
}

.match-preview__team-name--away {
  padding-left: 5px;
}

.match-preview__team-name--looser {
  color: #828282;
}

.match-preview__team-name:hover, .match-preview__team-name:hover .match-preview__team-name--looser, .match-preview__team-name:hover .match-preview__score:before {
  color: #d21e1e;
}

.match-preview__team-logo {
  max-width: 100%;
  height: auto;
}

.match-preview__date-score-container {
  flex-flow: column;
  flex-basis: 23%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.match-preview__date-container {
  flex-flow: column;
  place-content: center;
  display: flex;
}

.match-preview__date {
  font-size: 13px;
  font-weight: 400;
}

.match-preview__time {
  font-size: 13px;
  font-weight: 700;
}

.match-preview__flag {
  text-align: center;
  height: 13px;
  font-size: 10px;
  font-weight: 700;
}

.match-preview__flag--live {
  color: #d21e1e;
  text-transform: uppercase;
}

.match-preview__score {
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.match-preview__score--pen-sn:before, .match-preview__score--pen-ot:before, .match-preview__score--pen:before {
  z-index: 3;
  color: #1e1e1e;
  width: 14px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  display: block;
  position: absolute;
  bottom: 8px;
  right: -18px;
}

.match-preview__score--pen-sn:before {
  content: "SN";
}

.match-preview__score--pen-ot:before {
  content: "P";
}

.match-preview__series-score {
  text-align: center;
  width: 100%;
  font-size: 12px;
}

.match-preview__match-bet-cover {
  width: 100%;
}

.match-preview__match-bet-logo {
  width: 50px;
  height: auto;
  margin: 2px auto;
}

.match-preview__match-bet-logo--large {
  width: 64px;
  height: auto;
}

.match-preview--mobile .match-preview__team-name {
  font-size: 13px;
}

.match-preview--mobile:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-tennis {
  color: #1e1e1e;
  padding: 5px 0;
}

.match-preview-tennis:nth-child(2n) {
  background-color: #fff;
}

.match-preview-tennis__link {
  color: #1e1e1e;
  flex-flow: column;
  font-family: roboto, arial, sans-serif;
  display: flex;
  position: relative;
}

.match-preview-tennis__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview-tennis__link:hover .match-preview-tennis__date-time, .match-preview-tennis__link:hover .match-preview-tennis__set-value:last-of-type {
  color: #d21e1e;
}

.match-preview-tennis__player-container {
  margin: 2px 0;
  display: flex;
}

.match-preview-tennis__player {
  flex-basis: 50%;
  align-items: center;
  display: flex;
}

.match-preview-tennis__logo {
  width: auto;
  height: 25px;
}

.match-preview-tennis__player-name {
  flex-basis: calc(100% - 32px);
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-tennis__score {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-tennis__score--live {
  color: #d21e1e;
}

.match-preview-tennis__set-container {
  flex-basis: 50%;
  align-items: center;
  padding-left: 25px;
  display: flex;
}

.match-preview-tennis__set-value {
  text-align: left;
  flex-basis: 25px;
  font-size: 13px;
  font-weight: 400;
}

.match-preview-tennis__set-value:last-of-type {
  color: #ff6000;
}

.match-preview-tennis__date-time {
  color: #828282;
  flex-flow: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding-left: 26px;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.match-preview-tennis--mobile .match-preview-tennis__player-name, .match-preview-tennis--mobile .match-preview-tennis__score {
  font-size: 13px;
}

.match-preview-tennis--mobile .match-preview-tennis__player-name {
  line-height: 16px;
}

.match-preview-tennis--mobile:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-other {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview-other:first-child {
  border-top: 1px solid #dcdcdc;
}

.match-preview-other__link {
  color: #1e1e1e;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview-other__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview-other__link:hover .match-preview-other__date-time, .match-preview-other__link:hover .match-preview-other__event-description, .match-preview-other__link:hover .match-preview-other__event-result {
  color: #d21e1e;
}

.match-preview-other__date-time-container {
  border-right: 1px solid #dcdcdc;
  flex-basis: 20%;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 10px 5px;
  display: flex;
}

.match-preview-other__date-time {
  color: #828282;
  font-size: 13px;
  font-weight: 700;
}

.match-preview-other__event-container {
  flex-basis: 80%;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 10px;
  display: flex;
}

.match-preview-other__event-description {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-other__event-result {
  color: #828282;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.match-preview-other__icon--live {
  color: #d21e1e;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.match-preview-other--colors:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-other--mobile .match-preview-other__date-time-container {
  padding: 5px 10px;
}

.match-preview-other--mobile .match-preview-other__event-description {
  font-size: 13px;
}

.match-preview-other--mobile .match-preview-other__event-result {
  margin-top: 0;
  font-size: 13px;
}

.promo-leagues {
  background: #f5f5f5;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
  transition: all 1s ease-in-out !important;
}

.promo-leagues__header {
  border-bottom: 2px solid #dcdcdc;
  height: 60px;
  display: flex;
}

.promo-leagues__title {
  text-transform: uppercase;
  color: #d21e1e;
  align-items: center;
  margin-right: 20px;
  padding-top: 10px;
  font-family: roboto, arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.promo-leagues__title:after {
  content: "";
  background-color: #d21e1e;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues__nav {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.promo-leagues__nav-item {
  border-left: 1px solid #dcdcdc;
  align-items: center;
  padding: 10px 20px 0;
  display: flex;
}

.promo-leagues__nav-link {
  text-transform: uppercase;
  font-family: roboto, arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .promo-leagues__nav-link {
    font-size: 16px;
  }
}

.promo-leagues__list {
  padding-bottom: 53px;
}

.promo-leagues__btn {
  cursor: pointer;
  background: none;
  border: none;
}

.promo-leagues__btn:focus {
  outline: none;
}

.promo-leagues__btn--link {
  color: #d21e1e;
  height: 53px;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
}

.promo-leagues__btn--link:hover {
  text-decoration: none;
}

.promo-leagues__footer {
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues .title-section .icon-section {
  margin-top: 0;
  margin-right: 5px;
}

.promo-leagues.results-wrapper--collapsed:before {
  content: "";
  z-index: 1;
  background: #f5f5f5;
  width: 100%;
  height: 53px;
  transition: all 1s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues .results--no-collapsing {
  padding-bottom: 25px;
}

.promo-leagues .results--no-collapsing + .promo-leagues__footer {
  display: none;
}

.promo-leagues--mobile {
  background: #fff;
}

.promo-leagues--mobile .promo-leagues__title {
  font-size: 16px;
}

.promo-leagues--mobile .promo-leagues__nav {
  display: none;
}

.promo-leagues--mobile.results-wrapper--collapsed:before {
  background: #fff;
}

